import { toPairs, defaultsDeep } from 'lodash';

interface IFactory<T> {
    new (...args): T;
}

interface ISaturator<T> {
    (val: any, fullObj?: any): T;
    applyToNull?: boolean; // apply filter even if property was not on the parent object
}

export class BaseClass {
    constructor(args: any) {
        Object.assign(this, args);
    }

    static getDeserializer<T extends BaseClass>(
        this: IFactory<T>,
        fields: { [P in keyof Partial<T>]: ISaturator<T[P]> },
    ) {
        const fieldEntries = toPairs<ISaturator<any>>(fields);

        return (rawData: any): Partial<T> => {
            const rtn = { ...rawData };

            for (const [path, filter] of fieldEntries) {
                const val = rtn[path];
                rtn[path] = val != null || filter.applyToNull === true ? filter(val, rawData) : null;
            }

            return rtn;
        };
    }
}

export function ensureProperty<T>(filter: ISaturator<T>) {
    filter.applyToNull = true;
    return filter;
}

export function defaults<T>(defaultObj: T): ISaturator<T> {
    return ensureProperty((currObject) => defaultsDeep({}, currObject, defaultObj));
}

export default BaseClass;
