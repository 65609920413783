import { BaseClass } from '../../utils/api/BaseClass';
import { schema } from 'reports/models/schema';

/**
 * Based off the stripe Product object: https://stripe.com/docs/api/products/object
 */
class Product extends BaseClass {
    id: string;
    active: boolean;
    description: string;
    name: string;
}

const schemaObj = schema.addObject(Product, 'product', { idName: 'id' });

export { schemaObj, Product };
export default Product;
