/* eslint-disable no-multi-spaces, array-bracket-spacing */
import * as THREE from 'three';
import { toRadians } from './math';

// named indexes into Matrix.mat for a given row/column
const M00 = 0;
const M01 = 1;
const M02 = 2;
const M03 = 3;
const M10 = 4;
const M11 = 5;
const M12 = 6;
const M13 = 7;
const M20 = 8;
const M21 = 9;
const M22 = 10;
const M23 = 11;
const M30 = 12; // eslint-disable-line no-unused-vars
const M31 = 13; // eslint-disable-line no-unused-vars
const M32 = 14; // eslint-disable-line no-unused-vars
const M33 = 15; // eslint-disable-line no-unused-vars

export class Matrix {

    constructor(arr = []) {
        this.mat = arr.flat();
    }

    get(i, j) {
        return this.mat[i * 4 + j];
    }

    /**
     * return [Other] x [this]
     */
    // based on https://github.com/toji/gl-matrix/blob/master/src/gl-matrix/mat4.js#L700
    _transformMatrix(other) {
        const [
            a00, a01, a02, a03,
            a10, a11, a12, a13,
            a20, a21, a22, a23,
            a30, a31, a32, a33,
        ] = this.mat;


        const [
            b00, b01, b02, b03,
            b10, b11, b12, b13,
            b20, b21, b22, b23,
            b30, b31, b32, b33,
        ] = other.mat;

        // Cache only the current line of the second matrix
        return new Matrix([
            b00 * a00 + b01 * a10 + b02 * a20 + b03 * a30,
            b00 * a01 + b01 * a11 + b02 * a21 + b03 * a31,
            b00 * a02 + b01 * a12 + b02 * a22 + b03 * a32,
            b00 * a03 + b01 * a13 + b02 * a23 + b03 * a33,

            b10 * a00 + b11 * a10 + b12 * a20 + b13 * a30,
            b10 * a01 + b11 * a11 + b12 * a21 + b13 * a31,
            b10 * a02 + b11 * a12 + b12 * a22 + b13 * a32,
            b10 * a03 + b11 * a13 + b12 * a23 + b13 * a33,

            b20 * a00 + b21 * a10 + b22 * a20 + b23 * a30,
            b20 * a01 + b21 * a11 + b22 * a21 + b23 * a31,
            b20 * a02 + b21 * a12 + b22 * a22 + b23 * a32,
            b20 * a03 + b21 * a13 + b22 * a23 + b23 * a33,

            b30 * a00 + b31 * a10 + b32 * a20 + b33 * a30,
            b30 * a01 + b31 * a11 + b32 * a21 + b33 * a31,
            b30 * a02 + b31 * a12 + b32 * a22 + b33 * a32,
            b30 * a03 + b31 * a13 + b32 * a23 + b33 * a33,
        ]);
    }

    /**
     * apply the matrix to whatever is passed in (Matrix, Vector, or Array)
     */
    transform(vecListOrMatrix) {
        if (Array.isArray(vecListOrMatrix)) {
            return vecListOrMatrix.map(vec => this.transform(vec));
        } else if (vecListOrMatrix instanceof Matrix) {
            return this._transformMatrix(vecListOrMatrix);
        }

        // else this is a vector
        return vecListOrMatrix.transform(this);
    }

    // arr is an array of Vector. Transform each vector in array, in place,
    // by this matrix
    transformVectorArrayInPlace(arr) {
        for (let i = 0; i < arr.length; i++) {
            arr[i].transformSelf(this);
        }
    }

    translate(x, y, z) {
        if (x instanceof Vector) {
            return this.transform(Matrix.translate(x.x, x.y, x.z));
        }
        return this.transform(Matrix.translate(x, y, z));
    }

    rotateX(degrees) {
        return this.transform(Matrix.rotateX(degrees));
    }

    rotateZ(degrees) {
        return this.transform(Matrix.rotateZ(degrees));
    }

    scale(sx, sy, sz) {
        return this.transform(Matrix.scale(sx, sy, sz));
    }


    // http://www.euclideanspace.com/maths/algebra/matrix/functions/inverse/fourD/index.htm
    invert() {
        const [
            m00, m01, m02, m03,
            m10, m11, m12, m13,
            m20, m21, m22, m23,
            m30, m31, m32, m33,
        ] = this.mat;

        const determinant = this.determinant();

        const out = [ /* eslint-disable max-len */
            (m12 * m23 * m31 - m13 * m22 * m31 + m13 * m21 * m32 - m11 * m23 * m32 - m12 * m21 * m33 + m11 * m22 * m33) / determinant,
            (m03 * m22 * m31 - m02 * m23 * m31 - m03 * m21 * m32 + m01 * m23 * m32 + m02 * m21 * m33 - m01 * m22 * m33) / determinant,
            (m02 * m13 * m31 - m03 * m12 * m31 + m03 * m11 * m32 - m01 * m13 * m32 - m02 * m11 * m33 + m01 * m12 * m33) / determinant,
            (m03 * m12 * m21 - m02 * m13 * m21 - m03 * m11 * m22 + m01 * m13 * m22 + m02 * m11 * m23 - m01 * m12 * m23) / determinant,
            (m13 * m22 * m30 - m12 * m23 * m30 - m13 * m20 * m32 + m10 * m23 * m32 + m12 * m20 * m33 - m10 * m22 * m33) / determinant,
            (m02 * m23 * m30 - m03 * m22 * m30 + m03 * m20 * m32 - m00 * m23 * m32 - m02 * m20 * m33 + m00 * m22 * m33) / determinant,
            (m03 * m12 * m30 - m02 * m13 * m30 - m03 * m10 * m32 + m00 * m13 * m32 + m02 * m10 * m33 - m00 * m12 * m33) / determinant,
            (m02 * m13 * m20 - m03 * m12 * m20 + m03 * m10 * m22 - m00 * m13 * m22 - m02 * m10 * m23 + m00 * m12 * m23) / determinant,
            (m11 * m23 * m30 - m13 * m21 * m30 + m13 * m20 * m31 - m10 * m23 * m31 - m11 * m20 * m33 + m10 * m21 * m33) / determinant,
            (m03 * m21 * m30 - m01 * m23 * m30 - m03 * m20 * m31 + m00 * m23 * m31 + m01 * m20 * m33 - m00 * m21 * m33) / determinant,
            (m01 * m13 * m30 - m03 * m11 * m30 + m03 * m10 * m31 - m00 * m13 * m31 - m01 * m10 * m33 + m00 * m11 * m33) / determinant,
            (m03 * m11 * m20 - m01 * m13 * m20 - m03 * m10 * m21 + m00 * m13 * m21 + m01 * m10 * m23 - m00 * m11 * m23) / determinant,
            (m12 * m21 * m30 - m11 * m22 * m30 - m12 * m20 * m31 + m10 * m22 * m31 + m11 * m20 * m32 - m10 * m21 * m32) / determinant,
            (m01 * m22 * m30 - m02 * m21 * m30 + m02 * m20 * m31 - m00 * m22 * m31 - m01 * m20 * m32 + m00 * m21 * m32) / determinant,
            (m02 * m11 * m30 - m01 * m12 * m30 - m02 * m10 * m31 + m00 * m12 * m31 + m01 * m10 * m32 - m00 * m11 * m32) / determinant,
            (m01 * m12 * m20 - m02 * m11 * m20 + m02 * m10 * m21 - m00 * m12 * m21 - m01 * m10 * m22 + m00 * m11 * m22) / determinant,
        ]; /* eslint-enable */

        return (new Matrix(out));
    }

    normalize() {
        const scale = 1 / this.determinant();
        return this.scale(scale, scale, scale);
    }

    // http://www.euclideanspace.com/maths/algebra/matrix/functions/inverse/fourD/index.htm
    determinant() {
        const [
            m00, m01, m02, m03,
            m10, m11, m12, m13,
            m20, m21, m22, m23,
            m30, m31, m32, m33,
        ] = this.mat;

        return (
            m03 * m12 * m21 * m30 - m02 * m13 * m21 * m30 - m03 * m11 * m22 * m30 + m01 * m13 * m22 * m30 +
            m02 * m11 * m23 * m30 - m01 * m12 * m23 * m30 - m03 * m12 * m20 * m31 + m02 * m13 * m20 * m31 +
            m03 * m10 * m22 * m31 - m00 * m13 * m22 * m31 - m02 * m10 * m23 * m31 + m00 * m12 * m23 * m31 +
            m03 * m11 * m20 * m32 - m01 * m13 * m20 * m32 - m03 * m10 * m21 * m32 + m00 * m13 * m21 * m32 +
            m01 * m10 * m23 * m32 - m00 * m11 * m23 * m32 - m02 * m11 * m20 * m33 + m01 * m12 * m20 * m33 +
            m02 * m10 * m21 * m33 - m00 * m12 * m21 * m33 - m01 * m10 * m22 * m33 + m00 * m11 * m22 * m33
        );
    }

    transpose() {
        const [
            m00, m01, m02, m03,
            m10, m11, m12, m13,
            m20, m21, m22, m23,
            m30, m31, m32, m33,
        ] = this.mat;

        return new Matrix([
            m00, m10, m20, m30,
            m01, m11, m21, m31,
            m02, m12, m22, m32,
            m03, m13, m23, m33,
        ]);
    }

    get33() {
        return new Matrix([
            this.mat[M00], this.mat[M01], this.mat[M02], 0,
            this.mat[M10], this.mat[M11], this.mat[M12], 0,
            this.mat[M20], this.mat[M21], this.mat[M22], 0,
            0,             0,             0,             1,
            ]);
    }

    static rotateAxis(degrees, axis) {
        const { x, y, z } = axis;

        const rads = toRadians(degrees);
        const c = Math.cos(rads);
        const s = Math.sin(rads);
        const ic = 1.0 - c;

        return new Matrix([
            x * x * ic + c,     y * x * ic - z * s, z * x * ic + y * s, 0.0,
            x * y * ic + z * s, y * y * ic + c,     z * y * ic - x * s, 0.0,
            x * z * ic - y * s, y * z * ic + x * s, z * z * ic + c,     0.0,
            0.0,                0.0,                0.0,                1.0,
            ]);
    }

    static rotateZ(degrees, origin = null) {
        const radians = toRadians(degrees);
        const cos = Math.cos(radians);
        const sin = Math.sin(radians);
        let tx = 0;
        let ty = 0;

        if (origin) {
            const { x, y } = origin;
            tx = x - x * cos + y * sin;
            ty = y - x * sin - y * cos;
        }

        return new Matrix([
            cos, -sin, 0, tx,
            sin,  cos, 0, ty,
            0,      0, 1, 0,
            0,      0, 0, 1,
        ]);
    }

    static rotateX(degrees, origin = null) {
        const radians = toRadians(degrees);
        const cos = Math.cos(radians);
        const sin = Math.sin(radians);
        let tx = 0;
        let ty = 0;

        if (origin) {
            const { x, y } = origin;
            tx = x - x * cos + y * sin;
            ty = y - x * sin - y * cos;
        }

        return new Matrix([
            1,   0,    0, tx,
            0, cos, -sin, ty,
            0, sin,  cos, 0,
            0,   0,    0, 1,
        ]);
    }

    static translate(x, y, z) {
        if (x instanceof Vector) {
            return Matrix.translate(x.x, x.y, x.z);
        }

        return new Matrix([
            1, 0, 0, x,
            0, 1, 0, y,
            0, 0, 1, z,
            0, 0, 0, 1,
        ]);
    }

    static scale(sx, sy, sz) {
        if (sx instanceof Vector) {
            return Matrix.scale(sx.x, sx.y, sx.z);
        }

        return new Matrix([
            sx,  0,  0, 0,
            0,  sy,  0, 0,
            0,   0, sz, 0,
            0,   0,  0, 1,
        ]);
    }

    static identity() {
        return new Matrix([
            1, 0, 0, 0,
            0, 1, 0, 0,
            0, 0, 1, 0,
            0, 0, 0, 1,
        ]);
    }
}

export class Vector {

    constructor(x, y, z = 0) {
        if (x instanceof Object) {
            this.x = x.x;
            this.y = x.y;
            this.z = (x.z || 0);
        } else {
            this.x = x;
            this.y = y;
            this.z = z;
        }
    }

    element(dim) {
        if (dim === 0) return this.x;
        else if (dim === 1) return this.y;
        else if (dim === 2) return this.z;
        return null;
    }

    add(vec) {
        if (typeof vec === 'number') {
            return new Vector(this.x + vec, this.y + vec, this.z + vec);
        }
        return new Vector(this.x + vec.x, this.y + vec.y, this.z + vec.z);
    }

    addXYZ(x, y, z) {
        return new Vector(this.x + x, this.y + y, this.z + z);
    }

    addSelf(vec) {
        this.x += vec.x;
        this.y += vec.y;
        this.z += vec.z;

        return this;
    }

    setVec(vec) {
        this.x = vec.x;
        this.y = vec.y;
        this.z = vec.z;

        return this;
    }

    subtractSelf(vec) {
        this.x -= vec.x;
        this.y -= vec.y;
        this.z -= vec.z;

        return this;
    }

    subtract(vec) {
        if (typeof vec === 'number') {
            return new Vector(this.x - vec, this.y - vec, this.z - vec);
        }
        return new Vector(this.x - vec.x, this.y - vec.y, this.z - vec.z);
    }

    cosTheta(vec) {
        return this.dot(vec) / (this.length() * vec.length());
    }

    scale(x, optionalY, optionalZ) {
        if (optionalY !== undefined && optionalZ !== undefined) {
            return new Vector(this.x * x, this.y * optionalY, this.z * optionalZ);
        }
        return new Vector(this.x * x, this.y * x, this.z * x);
    }

    scaleSelf(x, optionalY = x, optionalZ = x) {
        this.x *= x;
        this.y *= optionalY;
        this.z *= optionalZ;

        return this;
    }

    multiply(vec) {
        return new Vector(this.x * vec.x, this.y * vec.y, this.z * vec.z);
    }

    dot(vec) {
        return this.x * vec.x + this.y * vec.y + this.z * vec.z;
    }

    cross(vec) {
        const x = this.y * vec.z - this.z * vec.y;
        const y = this.z * vec.x - this.x * vec.z;
        const z = this.x * vec.y - this.y * vec.x;

        return new Vector(x, y, z);
    }

    distance(vec) {
        const x = this.x - vec.x;
        const y = this.y - vec.y;
        const z = this.z - vec.z;

        return Math.sqrt(x * x + y * y + z * z);
    }

    /**
     * rotate around the Z-axis
     */
    rotate(degrees, origin) {
        // same as rotateZ but allows for optional origin
        // Note: most calls don't provide origin so for perf consider splitting
        // into rotateWithOrigin(degrees, origin) and rotate(degrees)
        const matrix = Matrix.rotateZ(degrees);
        if (origin) {
            return this.subtract(origin).transformSelf(matrix).addSelf(origin);
        }
        return this.transform(matrix);
    }

    /**
     * rotate around the Z-axis
     */
    rotateZ(degrees) {
        const matrix = Matrix.rotateZ(degrees);
        return this.transform(matrix);
    }

    rotateZSelf(degrees) {
        const matrix = Matrix.rotateZ(degrees);
        return this.transformSelf(matrix);
    }

    /**
     * rotate around the X-axis
     */
    rotateX(degrees) {
        const matrix = Matrix.rotateX(degrees);
        return this.transform(matrix);
    }

    /**
     * rotate around the X-axis, in place
     */
    rotateXSelf(degrees) {
        const matrix = Matrix.rotateX(degrees);
        return this.transformSelf(matrix);
    }

    transform(matrix) {
        const { x, y, z } = this;
        const mat = matrix.mat;

        return new Vector(
            x * mat[M00] + y * mat[M01] + z * mat[M02] + mat[M03],
            x * mat[M10] + y * mat[M11] + z * mat[M12] + mat[M13],
            x * mat[M20] + y * mat[M21] + z * mat[M22] + mat[M23]
        );
    }

    transformSelf(matrix) {
        const { x, y, z } = this;
        const mat = matrix.mat;

        this.x = x * mat[M00] + y * mat[M01] + z * mat[M02] + mat[M03];
        this.y = x * mat[M10] + y * mat[M11] + z * mat[M12] + mat[M13];
        this.z = x * mat[M20] + y * mat[M21] + z * mat[M22] + mat[M23];
        return this;
    }

    length() {
        return Math.sqrt(this.x * this.x + this.y * this.y + this.z * this.z);
    }

    lengthSq() {
        return this.x * this.x + this.y * this.y + this.z * this.z;
    }

    normalize(scaleFactor = 1) {
        return this.scale(scaleFactor / this.length());
    }

    toArray() {
        return [this.x, this.y, this.z];
    }

    equals(other) {
        return this.x === other.x && this.y === other.y && this.z === other.z;
    }

    approx(other, tolerance = 1e-6) {
        return (
            Math.abs(this.x - other.x) < tolerance &&
            Math.abs(this.y - other.y) < tolerance &&
            (// covers cases where Z is undefined/null (undefined == null, but not 0)
                (this.z == other.z) || // eslint-disable-line eqeqeq
                Math.abs(this.z - other.z) < tolerance
            )
        );
    }


    getCopy() {
        return new Vector(this.x, this.y, this.z);
    }

    getCopy2() {
        return new Vector(this.x, this.y);
    }

    toString() {
        if (this.z === undefined) {
            return `${this.constructor.name}(${this.x}, ${this.y})`;
        }
        return `${this.constructor.name}(${this.x}, ${this.y}, ${this.z})`;
    }

    toThree() {
        return new THREE.Vector3(this.x, this.y, this.z);
    }

    static fromObject(obj) {
        return new Vector(obj.x, obj.y, (obj.z || 0));
    }

    static fromObject2(obj) {
        return new Vector(obj.x, obj.y);
    }

    /**
     * return a vector describing the direction (normal) for a ray;
     */
    static createRay(elevation, azimuth) {
        // elevation 0 is ground level, 90 is straight up
        // azimuth 180 is due south
        const phi = toRadians((90 - azimuth) % 360);
        const theta = toRadians(90 - elevation);

        return new Vector(
            -Math.sin(theta) * Math.cos(phi), // dx
            -Math.sin(theta) * Math.sin(phi), // dy
            -Math.cos(theta),            // dz
        );
    }
}

// create a strong reference to Vector and Matrix objects so that
// they don't get de-optimized permanently
// see http://benediktmeurer.de/2016/10/11/the-case-of-temporary-objects-in-chrome/
// and https://github.com/aurorasolar/helioscope/issues/724
export const VECTOR_REF_TO_PREVENT_DEOPT = new Vector(0, 1, 1);
export const MATRIX_REF_TO_PREVENT_DEOPT = new Matrix();

// a shorter way to format x,y,z of a vector, useful for debugging
export function fmtVec(v) {
    if (!v) {
        return 'undefined';
    }
    const x = v.x.toFixed(3);
    const y = v.y.toFixed(3);
    const z = v.z.toFixed(3);
    return `${x}, ${y}, ${z}`;
}

// like fmtVec but only for x,y
export function fmtVec2(v) {
    if (!v) {
        return 'undefined';
    }
    const x = v.x.toFixed(3);
    const y = v.y.toFixed(3);
    return `${x}, ${y}`;
}
