/**
 * Predeclared messages file for react-intl
 *
 * The `defineMessages` call is so that all messages can be parsed out via AST for translation.
 */

import { defineMessages } from 'react-intl';

const DESIGN_STRINGS = defineMessages({
    field_segments: {
        id: 'design.field_segments',
        description: 'Field Segments (i.e. groups of solar modules) (used by FieldSegmentsTable, DesignSnapshotForm)',
        defaultMessage: 'Field Segments',
    },
    ac_interconnect: {
        id: 'design.ac_interconnect',
        description: 'solar design AC Interconnect',
        defaultMessage: 'AC Interconnect',
    },
    component: {
        id: 'design.component',
        description: 'solar design component (e.g. module or power device)',
        defaultMessage: 'Component',
    },
    component_type: {
        id: 'design.component_type',
        description: 'solar design component type (as in module or power device)',
        defaultMessage: 'Type',
    },
    combiner_poles: {
        id: 'design.combiner_poles',
        description: 'solar design Combiner Poles',
        defaultMessage: 'Combiner Poles',
    },
    string_size: {
        id: 'design.string_size',
        description: 'solar design String Size',
        defaultMessage: 'String Size',
    },
    stringing_strategy: {
        id: 'design.stringing_strategy',
        description: 'solar design Stringing Strategy',
        defaultMessage: 'Stringing Strategy',
    },
    nameplate: {
        id: 'design.nameplate',
        description: 'Nameplate (i.e. solar design nameplate power)',
        defaultMessage: 'Nameplate',
    },
    nameplate_tooltip: {
        id: 'simulation.nameplate_tooltip',
        description: 'Tooltip for design Nameplate power',
        defaultMessage: `Nameplate power available to the modules (i.e. module nameplate power *
            available irradiance).`,
    },
    racking: {
        id: 'design.racking',
        description: 'Racking (as in solar module racking)',
        defaultMessage: 'Racking',
    },
    orientation: {
        id: 'design.orientation',
        description: 'Orientation (as in solar module orientation)',
        defaultMessage: 'Orientation',
    },
    tilt: {
        id: 'design.tilt',
        description: 'Tilt (as in solar module tilt)',
        defaultMessage: 'Tilt',
    },
    azimuth: {
        id: 'design.azimuth',
        description: `Azimuth (as in solar module or camera azimuth) (used in FieldSegmentsTable,
            ShadingByFieldSegmentTable, DesignSnapshotRender)`,
        defaultMessage: 'Azimuth',
    },
    intrarow_spacing: {
        id: 'design.intrarow_spacing',
        description: 'Intrarow Spacing (as in solar module intrarow spacing)',
        defaultMessage: 'Intrarow Spacing',
    },
    frame_size: {
        id: 'design.frame_size',
        description: 'Frame Size (as in solar module frame size)',
        defaultMessage: 'Frame Size',
    },
    frames: {
        id: 'design.frames',
        description: 'Frames (as in number of solar module frames)',
        defaultMessage: 'Frames',
    },
    modules: {
        id: 'design.modules',
        description: `Modules (as in solar modules) (used in FieldSegmentsTable,
            ShadingByFieldSegmentTable, DesignSnapshotForm)`,
        defaultMessage: 'Modules',
    },
    module_dc_nameplate: {
        id: 'design.module_dc_nameplate',
        description: 'Module DC Nameplate',
        defaultMessage: 'Module DC Nameplate',
    },
    inv_ac_nameplate: {
        id: 'design.inv_ac_nameplate',
        description: 'Inverter AC Nameplate',
        defaultMessage: 'Inverter AC Nameplate',
    },
    dc_nameplate_tooltip: {
        id: 'design.dc_nameplate_tooltip',
        description: 'DC Nameplate tooltip content',
        defaultMessage: 'Total nameplate power of all modules in the system.',
    },
    ac_nameplate_tooltip: {
        id: 'design.ac_nameplate_tooltip',
        description: 'AC Nameplate tooltip content',
        defaultMessage: 'Total power capacity of all inverters in the system.',
    },
    rack_type: {
        id: 'design.rack_type',
        description: 'Rack Type',
        defaultMessage: 'Rack Type',
    },
    module: {
        id: 'design.module',
        description: 'Module',
        defaultMessage: 'Module',
    },
    characterization: {
        id: 'design.characterization',
        description: 'Characterization (as in module characterization or power device characterization)',
        defaultMessage: 'Characterization',
    },
    device: {
        id: 'design.device',
        description: 'Device (as in power device)',
        defaultMessage: 'Device',
    },
    module_characterizations: {
        id: 'design.module_characterizations',
        description: 'Module Characterizations',
        defaultMessage: 'Module Characterizations',
    },
    component_characterizations: {
        id: 'design.component_characterizations',
        description: 'Component Characterizations',
        defaultMessage: 'Component Characterizations',
    },
    configure_render: {
        id: 'design.configure_render',
        description: 'Configure Render',
        defaultMessage: 'Configure Render',
    },
    edit_design_render: {
        id: 'design.edit_design_render',
        description: 'Edit Design Render button',
        defaultMessage: 'Edit Design Render',
    },
    keepouts: {
        id: 'design.keepouts',
        description: 'Keepouts (solar design terminology) (used in DesignSnapshotForm)',
        defaultMessage: 'Keepouts',
    },
    overlays: {
        id: 'design.overlays',
        description: 'Overlays (solar design terminology) (used in DesignSnapshotForm)',
        defaultMessage: 'Overlays',
    },
    trees: {
        id: 'design.trees',
        description: 'Trees (in the context of trees creating shade) (used in DesignSnapshotForm)',
        defaultMessage: 'Trees',
    },
    wiring: {
        id: 'design.wiring',
        description: 'Wiring (in the context of solar energy) (used in DesignSnapshotForm)',
        defaultMessage: 'Wiring',
    },
    inverters: {
        id: 'design.inverters',
        description: 'Inverters (in the context of solar energy) (used in DesignSnapshotForm)',
        defaultMessage: 'Inverters',
    },
    combiners: {
        id: 'design.combiners',
        description: 'Combiners (in the context of solar energy) (used in DesignSnapshotForm)',
        defaultMessage: 'Combiners',
    },
    interconnect: {
        id: 'design.interconnect',
        description: 'Interconnect (in the context of solar energy) (used in DesignSnapshotForm)',
        defaultMessage: 'Interconnect',
    },
    camera_angle: {
        id: 'design.camera_angle',
        description: 'Camera Angle (used in DesignSnapshotForm)',
        defaultMessage: 'Camera Angle',
    },
    recenter: {
        id: 'design.recenter',
        description: 'Recenter (as in recenter camera) (used in DesignSnapshotForm)',
        defaultMessage: 'Recenter',
    },
    module_color: {
        id: 'design.module_color',
        description: 'For picking a custom color for the modules',
        defaultMessage: 'Module Color',
    },
    single_color: {
        id: 'design.single_color',
        description: 'For picking one custom color to render for all modules (as opposed to a multi-color heat map)',
        defaultMessage: 'Single Color',
    },
    heat_map: {
        id: 'design.heat_map',
        description: 'For a module-by-module heat map display of TSRF, solar access, etc.',
        defaultMessage: 'Heat Map',
    },
});

export default DESIGN_STRINGS;
