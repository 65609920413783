import Logger from 'js-logger';
import { fallback } from 'reports/utils/helpers';

const logger = Logger.get('utils');

/**
 * create a template template string for argument interoplation,
 * default is ```
 * const tmpl = new Template(`//{var1}/{var2}`);
 * tmpl.compile({var1: 'hello', var2: 'world; });// '//hello/world'
 */
export class Template {
    static DELIMS = ['{', '}'];
    static REGEX = new RegExp(Template.makeParam('\\s*[\\w\\.]+\\s*'), 'g');

    template: string;
    templateParams: string[];

    constructor(str: string) {
        this.template = str;
        const paramMatches = str.match(Template.REGEX);
        if (paramMatches != null) {
            this.templateParams = paramMatches.map((x) => fallback(x.match(/[\w\.]+/), [''])[0]);
        } else {
            this.templateParams = [];
        }
    }

    compile(params: { [k: string]: string | number }, silent: boolean = false) {
        let rtn = this.template;

        for (const paramName of this.templateParams) {
            const paramVal = params[paramName];

            if (paramVal != null) {
                rtn = rtn.replace(Template.makeParam(paramName), String(paramVal));
            } else {
                const error = `Could not compile template ${this.template}, missing ${paramName}`;

                if (silent) {
                    logger.warn(error);
                } else {
                    throw new Error(error);
                }
            }
        }

        return rtn;
    }

    static makeParam(param) {
        const [start, end] = this.DELIMS;
        return `${start}${param}${end}`;
    }
}

export default Template;
