/* tslint:disable:variable-name */
import { BaseClass } from 'reports/utils/api';

import { schema } from './schema';

import type { WeatherSource } from './weather_source';

class WeatherDataset extends BaseClass {
    weather_dataset_id: number;

    weather_source_id: number;
    weather_source: WeatherSource;

    name: string;

    get_url: string;
    original_weather_dataset_id: number;
    s3_bucket: string;
    s3_key: string;
    sample_length: number;
    source_format: any;
    spectral_analysis_source_type: string;
    status: string;
    version: number;

    toString() {
        return `${this.name}, ${this.weather_source}`;
    }
}

const schemaObj = schema.addObject(WeatherDataset, 'weather_dataset');

export { WeatherDataset, schemaObj };
