/**
 * TODO: these probably could be just thunks, not wrapped as full action creators
 */

import Logger from 'js-logger';
import { omit, mapValues, isEqual } from 'lodash';
import { request, superagent, HTTPMethod } from 'reports/modules/request';

import { AsyncActionCreators, actionCreatorFactory, ActionCreatorFactory, Action } from 'typescript-fsa';

import { ThunkAction } from 'redux-thunk';
import { createAsyncWorkflow } from 'reports/utils/async_helpers';
import { Template } from 'reports/utils/Template';
import { DeepPartial } from 'reports/utils/types';

import { SchemaObject, IDeepSelect, ISelector } from './Schema';

const logger = Logger.get('api');

interface HTTPError extends Error {
    response?: superagent.Response;
    status?: number;
}

interface IRequestFunction<IBody = any, IParams = any, IResp = any> {
    (body?: IBody, urlParams?: IParams): Promise<IResp>;
    url: (body?: IBody, urlParams?: IParams) => string;
}

interface ISchemaEndpointConfig<I> {
    deepSelect?: IDeepSelect<I>; // deepselect options to add ot the object when selecting from state (onSuccess)
    includeRelationships?: (keyof I)[]; // relationships to include when sending to the server (presend)
}

const _getUrl = (req: superagent.SuperAgentRequest) => {
    (req as any)._finalizeQueryString();

    return req.url;
};

function parseFilesFromBody(requestBody: any = {}) {
    const json = {};
    let files;

    for (const key of Object.keys(requestBody)) {
        const val = requestBody[key];

        if (val instanceof File) {
            if (files == null) {
                files = {};
            }

            files[key] = val;
        } else {
            json[key] = val;
        }
    }

    return { json, files };
}

/**
 * return a typed function that interpolates URL params based on an object
 * will populate the URL from the params if possible
 * @param url an unparsed url string, params in {} will be interpolated
 * @param method the HTTP Method for resulting requests
 * @param formatQueryParams a function to preprocess query parameters into URL strings
 * @param responseType the type of data expected in the response body.
 */
function createRequestFunction<IBody = any, IParams = any, IResp = any>(
    url: string,
    method: HTTPMethod = 'GET',
    formatQueryParams: (args: any) => any = identity,
    responseType?: 'blob' | 'arraybuffer',
    // send credentials on redirects by default, set to false to allow cross-origin redirects (s3)
    withCredentials: boolean = true,
): IRequestFunction<IBody, IParams, IResp> {
    const template = new Template(url);

    const buildRequest = (body?: IBody, urlParams?: IParams) => {
        const { json, files } = parseFilesFromBody(body);

        // include the body to updating preexisting entities more straightforward
        const url = template.compile(Object.assign({}, json, urlParams) as any);

        let req;

        if (files == null) {
            req = request(method, url);

            // Only send payload if not empty for GET requests, empty payloads break s3 redirect signatures,
            // we do however send empty payloads for some POST endpoints (customer creation)
            if (!isEqual({}, json) || method !== 'GET') {
                req = req.send(json as any);
            }

            if (withCredentials) req = req.withCredentials();
        } else {
            req = request(method, url);

            // posting files to the server must involve a multipart form and file attachements
            // (not as a json request w/ the appropriate content type), so we need to manually
            // serialize each top level key of the body to a jsonified string when attaching files.
            // our flask implementation will transparently deserialize these as well.
            Object.keys(json).forEach((key) => (req = req.field(key, JSON.stringify(json[key]))));
            Object.keys(files).forEach((key) => (req = req.attach(key, files[key])));
        }

        if (urlParams != null) {
            req = req.query(formatQueryParams(omit(urlParams as any, template.templateParams)));
        }
        if (responseType) {
            req = req.responseType(responseType);
        }
        return req;
    };

    return Object.assign(
        async (body?: IBody, urlParams?: IParams): Promise<IResp> => {
            const req = buildRequest(body, urlParams);
            logger.info(`[${method}] ${_getUrl(req)}`);
            const response = await req;

            // TODO: error handling
            return response.body as IResp;
        },
        {
            url: (body?: IBody, urlParams?: IParams) => _getUrl(buildRequest(body, urlParams)),
        },
    );
}

export interface IAPIMethodAction<IParams, IResp>
    extends AsyncActionCreators<{ body: undefined; urlParams?: IParams }, IResp, HTTPError> {
    (params?: IParams): ThunkAction<Promise<IResp>, {}, any, any>;
    request: (params?: IParams) => Promise<IResp>;
    url: (params?: IParams) => string;
}

interface IAPIMethodActionBody<IBody, IParams, IResp>
    extends AsyncActionCreators<{ body: IBody; urlParams?: IParams }, IResp, HTTPError> {
    (body: IBody, params?: IParams): ThunkAction<Promise<IResp>, {}, any, any>;
    request: IRequestFunction<IBody, IParams, any>;
    url: (body?: IBody, params?: IParams) => string;
}

interface IPresend {
    (rawData: any): any;
}

interface IOnSuccess {
    (rawData: any, reqBody: any, reqParams: any): Action<any> | ThunkAction<any, any, any, any>;
}

interface IAPIMethodCreator<DefaultResponse> {
    <IParams = any, IResp = DefaultResponse>(url?: string, options?: IAPIConfig<IResp>): IAPIMethodAction<
        IParams,
        IResp
    >;
}

interface IAPIMethodCreatorBody<DefaultResponse, DefaultBody = DefaultResponse> {
    <IBody = DefaultBody, IParams = any, IResp = DefaultResponse>(
        url?: string,
        options?: IAPIConfig<IResp>,
    ): IAPIMethodActionBody<IBody, IParams, IResp>;
}

const identity = (x) => x;
const identitySelector = (_state, rawData) => rawData;

// overrides for configuring an individual api call
interface IAPIConfig<PrimaryClass> {
    presend?: IPresend;
    onSuccess?: IOnSuccess | null;
    selector?: ISelector<PrimaryClass>;
    responseType?: 'blob' | 'arraybuffer';
    withCredentials?: boolean;
}

// args for creating an entire endpoint
interface IConstructorArgs<PrimaryClass> {
    presend?: IPresend;
    onResponse?: IOnSuccess;
    onDelete?: IOnSuccess;
    selector?: ISelector<PrimaryClass>;
}

/**
 * create API endpoints with redux workflows with sane defaults can overried
 * types by passsing type definitons when defining endpoints
 */
export class ReduxEndpoint<PrimaryClass = any> {
    private baseUrl: string;
    private presend: IPresend;
    private onResponse: IOnSuccess | undefined;
    private onDelete: IOnSuccess | undefined;
    private selector: ISelector<PrimaryClass>;

    private actionCreatorFactory: ActionCreatorFactory;

    index: IAPIMethodCreator<PrimaryClass[]>;
    get: IAPIMethodCreator<PrimaryClass>;
    head: IAPIMethodCreator<null>;
    delete: IAPIMethodCreator<null>;
    post: IAPIMethodCreatorBody<PrimaryClass, DeepPartial<PrimaryClass>>;
    put: IAPIMethodCreatorBody<PrimaryClass>;
    patch: IAPIMethodCreatorBody<DeepPartial<PrimaryClass>, DeepPartial<PrimaryClass>>;

    /**
     * creates factory that can generate methods for typed interactions with an API
     * @param baseUrl the base URL endpoint for all the mothods
     * @param actionCallbacks calbacks that will be executed during the request lifecycle
     */
    constructor(
        baseUrl: string,
        {
            onResponse, // action to be dispatched with raw response data as the argument
            onDelete, // action to be dispatched with raw response data on delete requests
            selector = identitySelector, // selector to get an object from the state based on the raw data
            presend = identity, // filter to process the raw request data before sending to the server
        }: IConstructorArgs<PrimaryClass> = {},
    ) {
        this.baseUrl = baseUrl;
        this.onResponse = onResponse;
        this.onDelete = onDelete;
        this.selector = selector;
        this.presend = presend;

        this.actionCreatorFactory = actionCreatorFactory(`endpoint:${this.baseUrl}`);

        // factory methods to create endpoint for each HTTP method type
        this.index = this.createEndpointFactory('GET', true);
        this.get = this.createEndpointFactory('GET');

        this.head = this.createEndpointFactory('HEAD');
        this.delete = this.createEndpointFactory('DELETE');

        this.post = this.createEndpointFactory('POST');
        this.put = this.createEndpointFactory('PUT');
        this.patch = this.createEndpointFactory('PATCH');
    }

    /**
     * create an endpoing automatically configured with callbacks from a schema object, assuming REST style interactions
     * will automatically sync api interactions to the state
     *
     * @param {string} baseUrl the base url for the endpoint
     * @param {SchemaObject<I>} schema the schema object
     * @param {ISchemaEndpointConfig<I>} config default options
     */
    static fromSchema<I>(baseUrl: string, schema: SchemaObject<I>, config: ISchemaEndpointConfig<I> = {}) {
        const { deepSelect, includeRelationships = [] } = config;
        const onResponse = schema.dataLoaded;
        const onDelete = (_resp, _body, params) => schema.entityDeleted(params);
        let selector = schema.selectByObject;
        if (deepSelect != null) {
            selector = (appState: any, obj: I | any) => schema.selectByObject(appState, obj, deepSelect);
        }
        const presend = (obj: DeepPartial<I>) => {
            return schema.pruneRelationships(obj, includeRelationships);
        };

        return new ReduxEndpoint<I>(baseUrl, {
            onResponse,
            onDelete,
            selector,
            presend,
        });
    }

    // DELETE and HEAD don't send a body and dont return a body
    private createEndpointFactory(method: 'DELETE' | 'HEAD'): IAPIMethodCreator<null>;

    // GET requests return full objects, but don't send a body
    private createEndpointFactory(method: 'GET'): IAPIMethodCreator<PrimaryClass>;
    private createEndpointFactory(method: 'GET', isArray: true): IAPIMethodCreator<PrimaryClass[]>;

    // PUT requests should send and receive valid objects by default
    private createEndpointFactory(method: 'PUT'): IAPIMethodCreatorBody<PrimaryClass>;
    private createEndpointFactory(method: 'PUT', isArray: true): IAPIMethodCreatorBody<PrimaryClass[]>;

    // PATCH requests dont need to send or receive a full body
    private createEndpointFactory(
        method: 'PATCH',
    ): IAPIMethodCreatorBody<DeepPartial<PrimaryClass>, DeepPartial<PrimaryClass>>;
    private createEndpointFactory(
        method: 'PATCH',
        isArray: true,
    ): IAPIMethodCreatorBody<DeepPartial<PrimaryClass>[], DeepPartial<PrimaryClass>[]>;

    // POST requests dont need to send a full body, but should receive one
    private createEndpointFactory(method: 'POST'): IAPIMethodCreatorBody<PrimaryClass, DeepPartial<PrimaryClass>>;
    private createEndpointFactory(
        method: 'POST',
        isArray: true,
    ): IAPIMethodCreatorBody<PrimaryClass[], DeepPartial<PrimaryClass>[]>;

    /**
     * create a factory that can be called to create endpoints that use a given HTTP method and by default have
     * typings that would be typical of rest
     *
     * leverage the type system to enforce http semantics:
     *  - no body for get/head/delete requests
     *  - no result (by default) for head/delete
     * @param method the HTTP method
     * @param isArray the HTTP method
     */
    private createEndpointFactory<IDefaultResult, IDefaultBody = IDefaultResult>(method, isArray = false) {
        const defaultSuccessFn = method !== 'DELETE' ? this.onResponse : this.onDelete;
        let defaultSelector = this.selector as any;

        if (isArray) {
            defaultSelector = (state: any, respArr: any[]) => respArr.map((resp) => this.selector(state, resp));
        }

        if (method === 'GET' || method === 'DELETE' || method === 'HEAD') {
            return <IParams = any, IResult = IDefaultResult>(url: string = '', config: IAPIConfig<IResult> = {}) => {
                const {
                    onSuccess = defaultSuccessFn,
                    selector = defaultSelector,
                    presend = this.presend,
                    responseType,
                    withCredentials,
                } = config;

                const endPoint = ReduxEndpoint.createEndpointMethod<undefined, IParams, IResult>(
                    this,
                    method,
                    this.baseUrl + url,
                    isArray,
                    presend,
                    onSuccess,
                    selector,
                    responseType,
                    withCredentials,
                );

                return Object.assign(
                    // for HTTP methods with no body, wrap the request so it requires no function args
                    (params?: IParams) => endPoint(undefined, params),
                    {
                        request: (params?: IParams) => endPoint.request(undefined, params),
                        url: (params?: IParams) => endPoint.url(undefined, params),
                    },
                ) as IAPIMethodAction<IParams, IResult>;
            };
        }

        return <IBody = IDefaultBody, IParams = any, IResp = IDefaultResult>(
            url: string = '',
            config: IAPIConfig<IResp> = {},
        ) => {
            const {
                onSuccess = defaultSuccessFn,
                selector = defaultSelector,
                presend = this.presend,
                responseType,
                withCredentials,
            } = config;

            return ReduxEndpoint.createEndpointMethod<IBody, IParams, IResp>(
                this,
                method,
                this.baseUrl + url,
                isArray,
                presend,
                onSuccess,
                selector,
                responseType,
                withCredentials,
            );
        };
    }

    private static createEndpointMethod<IBody, IParams, IResult>(
        inst: ReduxEndpoint<any>,
        method: HTTPMethod,
        url: string,
        isArray: boolean,
        presend: IPresend,
        onSuccess: IOnSuccess | undefined | null,
        selector: ISelector<IResult>,
        responseType?: 'blob' | 'arraybuffer',
        withCredentials?: boolean,
    ): IAPIMethodActionBody<IBody, IParams, IResult> {
        type MethodParams = { body: IBody; urlParams?: IParams };

        const requestFn = createRequestFunction<IBody, IParams, IResult>(
            url,
            method,
            this.formatQueryParams,
            responseType,
            withCredentials,
        );

        const asyncWorkflow = createAsyncWorkflow<MethodParams, IResult, HTTPError>(
            `${method}:${url}`, // action name
            inst.actionCreatorFactory, // action creator
            async (params, dispatch, getState) => {
                const x = presend(params.body);
                const respData = await requestFn(x, params.urlParams);

                if (onSuccess != null) {
                    if (isArray) {
                        (respData as any).map((data) => dispatch(onSuccess(data, params.body, params.urlParams)));
                    } else {
                        dispatch(onSuccess(respData, params.body, params.urlParams));
                    }
                }

                return selector(getState(), respData) as IResult;
            },
        );

        return Object.assign((body: IBody, urlParams?: IParams) => asyncWorkflow({ body, urlParams }), {
            request: requestFn,
            url: requestFn.url,
            ...asyncWorkflow,
        });
    }

    static urlParamFormatters = new Map();

    /**
     * add a formatter to all URLs to
     * @param cls any object contstructor / type that may be used as a URL Param
     * @param formatter function to serialie objects into a string for URL args
     */
    static addUrlFormatter<I>(cls: { new (): I }, formatter: (x: I) => string) {
        this.urlParamFormatters.set(cls, formatter);
        return this; // make it chainable
    }

    /**
     * format query parameters into an object for the URL string
     * @param rawParams an object of raw objects to be used as URL parameters
     */
    static formatQueryParams = (rawParams: any) => {
        const formatVal = (val) => {
            if (val == null || val.constructor == null) {
                return val;
            }
            const formatter = ReduxEndpoint.urlParamFormatters.get(val.constructor);
            return formatter ? formatter(val) : val;
        };

        return mapValues(rawParams, (val) => formatVal(val));
    };

    // default config for ignoring the state (e.g. just making a request and returning the response body)
    static PassThroughConfig(responseType?: 'blob' | 'arraybuffer', withCredentials?: boolean) {
        return {
            responseType,
            withCredentials,
            onSuccess: null,
            selector: identitySelector,
            presend: identity,
        };
    }
}

export default ReduxEndpoint;
