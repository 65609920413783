/**
 * Predeclared messages file for react-intl
 *
 * The `defineMessages` call is so that all messages can be parsed out via AST for translation.
 */

import { defineMessages } from 'react-intl';

const REPORT_STRINGS = defineMessages({
    editor_no_widgets: {
        id: 'report.editor.no_widgets_on_page',
        description: 'Report editor message for no widgets on page (used in Report)',
        defaultMessage: 'No widgets on this page',
    },
    editor_drag_widgets_here: {
        id: 'report.editor.drag_widgets_here',
        description: 'Report editor message for drag to add widgets (used in Report)',
        defaultMessage: 'Drag widgets here to add them to the page',
    },
    editor_delete_page: {
        id: 'report.editor.delete_page',
        description: 'Report editor delete this page button (used in Report)',
        defaultMessage: 'Delete this page',
    },
    editor_add_page: {
        id: 'report.editor.add_page',
        description: 'Report editor add page text (used in Report)',
        defaultMessage: 'Add a new page',
    },
    editor_drag_to_add: {
        id: 'report.editor.drag_to_add',
        description: 'Report editor add page text description (used in Report)',
        defaultMessage: 'Drag widgets here to create a new page',
    },
    editor_document_empty: {
        id: 'report.editor.document_empty',
        description: 'Report editor empty document text (used in Report)',
        defaultMessage: 'Document is Empty',
    },
    editor_add_widgets: {
        id: 'report.editor.add_widgets',
        description: 'Report editor add widgets panel header (used in FormatPanel)',
        defaultMessage: 'Add Widgets',
    },
    editor_filter_widgets: {
        id: 'report.editor.filter_widgets',
        description: 'Report editor filter widgets panel header (used in FormatPanel)',
        defaultMessage: 'Filter widgets',
    },
    editor_portrait: {
        id: 'report.editor.portrait',
        description: 'Portrait Orientation (used in FormatPanel)',
        defaultMessage: 'Portrait',
    },
    editor_landscape: {
        id: 'report.editor.landscape',
        description: 'Landscape Orientation (used in FormatPanel)',
        defaultMessage: 'Landscape',
    },
    editor_widget_scale: {
        id: 'report.editor.widget_scale',
        description: 'Widget Scale (how big the widgets are in the report) (used in FormatPanel)',
        defaultMessage: 'Widget Scale',
    },
    editor_classic_widgets: {
        id: 'report.editor.classic_widget_style',
        description: 'Whether to display widgets in classic style (used in FormatPanel)',
        defaultMessage: 'Classic Widget Style',
    },
});

export default REPORT_STRINGS;
