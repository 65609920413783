/* eslint-disable id-length,consistent-return */
/**
* Searches the interval from <tt>lowerLimit</tt> to <tt>upperLimit</tt>
* for a root (i.e., zero) of the function <tt>func</tt> with respect to
* its first argument using Brent's method root-finding algorithm.
*
* @param {function} function for which the root is sought.
* @param {number} the lower point of the interval to be searched.
* @param {number} the upper point of the interval to be searched.
* @param {number} the desired accuracy (convergence tolerance).
* @param {number} the maximum number of iterations.
* @returns an estimate for the root within accuracy.
*/
// Translated from zeroin.c in http://www.netlib.org/c/brent.shar.
export function rootFind(func, lowerLimit, upperLimit, errorTol = 0, maxIter = 1000) {
    let a = lowerLimit;
    let b = upperLimit;
    let c = a;
    let fa = func(a);
    let fb = func(b);
    let fc = fa;
    let actTol;   // Actual tolerance
    let newStep;  // Step at this iteration
    let prevStep; // Distance from the last but one to the last approximation
    let p;         // Interpolation step is calculated in the form p/q; division is delayed until the last moment
    let q;

    while (maxIter-- > 0) {
        prevStep = b - a;

        if (Math.abs(fc) < Math.abs(fb)) {
            // Swap data for b to be the best approximation
            a = b; b = c; c = a;
            fa = fb; fb = fc; fc = fa;
        }

        actTol = 1e-15 * Math.abs(b) + errorTol / 2;
        newStep = (c - b) / 2;

        if (Math.abs(newStep) <= actTol || fb === 0) {
            return b; // Acceptable approx. is found
        }

      // Decide if the interpolation can be tried
        if (Math.abs(prevStep) >= actTol && Math.abs(fa) > Math.abs(fb)) {
            // If prevStep was large enough and was in true direction, Interpolatiom may be tried
            const cb = c - b;
            let t1;
            let t2;

            if (a === c) { // If we have only two distinct points linear interpolation can only be applied
                t1 = fb / fa;
                p = cb * t1;
                q = 1.0 - t1;
            } else { // Quadric inverse interpolation
                q = fa / fc; t1 = fb / fc; t2 = fb / fa;
                p = t2 * (cb * q * (q - t1) - (b - a) * (t1 - 1));
                q = (q - 1) * (t1 - 1) * (t2 - 1);
            }

            if (p > 0) {
                q = -q;  // p was calculated with the opposite sign; make p positive
            } else {
                p = -p;  // and assign possible minus to q
            }

            if (p < (0.75 * cb * q - Math.abs(actTol * q) / 2) && p < Math.abs(prevStep * q / 2)) {
                // If (b + p / q) falls in [b,c] and isn't too large it is accepted
                newStep = p / q;
            }

        // If p/q is too large then the bissection procedure can reduce [b,c] range to more extent
        }

        if (Math.abs(newStep) < actTol) { // Adjust the step to be not less than tolerance
            newStep = (newStep > 0) ? actTol : -actTol;
        }

        a = b;
        fa = fb;     // Save the previous approx.
        b += newStep;
        fb = func(b);  // Do step to a new approxim.

        if ((fb > 0 && fc > 0) || (fb < 0 && fc < 0)) {
            c = a;
            fc = fa; // Adjust c for it to have a sign opposite to that of b
        }
    }
}
