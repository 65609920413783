import { ActionCreatorsMapObject, bindActionCreators, Dispatch } from 'redux';

/**
 * bind action creators to the redux dispatch
 *
 * @param actions if an object, wrap all the values with the dispatcher, if a
 *                function, evaluate with the component's ownProps and then
 *                wrap all the action creators with the dispatcher
 */
export function bindActions<IOwnProps extends object = any, AC extends ActionCreatorsMapObject = any>(
    actionCreators: AC | ((ownProps: IOwnProps) => AC),
) {
    return (dispatch: Dispatch, ownProps: IOwnProps) => {
        const actionMap: AC = typeof actionCreators === 'function' ? actionCreators(ownProps) : actionCreators;

        // this typecast does not get the return types correct for thunks
        return bindActionCreators(actionMap, dispatch);
    };
}
