import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

// Horizontal alignment
export enum Align {
    CENTER = 'center',
    LEFT = 'flex-start',
    RIGHT = 'flex-end',
    SPACE_BETWEEN = 'space-between',
}

// Vertical alignment
export enum AlignV {
    BOTTOM = 'flex-end',
    CENTER = 'center',
    TOP = 'flex-start',
}

const Container = styled.div<{ align?: Align; alignV?: AlignV }>`
    display: flex;

    &.center {
        align-items: center;
    }

    ${({ align }) => (align ? `justify-content: ${align};` : undefined)}
    ${({ alignV }) => (alignV ? `align-items: ${alignV};` : undefined)}
`;

const ContainerV = styled.div`
    display: flex;
    flex-direction: column;

    &.center {
        justify-content: center;
    }
`;

const Main = styled.div<{ hideOverflow?: boolean }>`
    flex: 1;

    ${({ hideOverflow }) => (hideOverflow ? 'overflow: hidden;' : undefined)}
`;

export default {
    Container,
    ContainerV,
    Main,

    Align,
    AlignV,
};
