/**
 * Predeclared messages file for react-intl
 *
 * The `defineMessages` call is so that all messages can be parsed out via AST for translation.
 */

import { defineMessages } from 'react-intl';

const GENERAL_STRINGS = defineMessages({
    logos: {
        id: 'general.logos',
        description: 'Logo images',
        defaultMessage: 'Logos',
    },
    report_images: {
        id: 'general.report_images',
        description: 'Previously uploaded images used in reports',
        defaultMessage: 'Report Images',
    },
    filter_by: {
        id: 'general.filter_by',
        description: 'Filter by the following categories (e.g. choose from different file types)',
        defaultMessage: 'Filter by',
    },
    image_fit: {
        id: 'general.image_fit',
        description: 'Refers to different ways of fitting an image to a fixed container size (e.g. cover, fill, etc.)',
        defaultMessage: 'Image Fit',
    },
    image_fit_fill: {
        id: 'general.image_fit_fill',
        description: 'Image fit type of fill',
        defaultMessage: 'Fill',
    },
    image_fit_cover: {
        id: 'general.image_fit_cover',
        description: 'Image fit type of cover',
        defaultMessage: 'Cover',
    },
    image_fit_contain: {
        id: 'general.image_fit_contain',
        description: 'Image fit type of contain',
        defaultMessage: 'Contain',
    },
    image_fit_none: {
        id: 'general.image_fit_none',
        description: 'Image fit type of none',
        defaultMessage: 'None',
    },
    fill_helper: {
        id: 'general.fill_helper',
        description: 'Helper text for image fit type of fill',
        defaultMessage: 'Stretch image to fit',
    },
    cover_helper: {
        id: 'general.cover_helper',
        description: 'Helper text for image fit type of cover',
        defaultMessage: 'Size image to fill height and width, cropping if necessary',
    },
    contain_helper: {
        id: 'general.contain_helper',
        description: 'Helper text for image fit type of contain',
        defaultMessage: 'Size full image to fill',
    },
    none_helper: {
        id: 'general.none_helper',
        description: 'Helper text for image fit type of none',
        defaultMessage: 'Keep original image dimensions',
    },
});

export default GENERAL_STRINGS;
