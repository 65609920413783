import Logger from 'js-logger';

import lodash from 'lodash';

import { FLError } from 'helioscope/app/utilities/helpers';
import * as solaredge from './solaredge';
import * as scheduling from './inverter_scheduling';

const logger = Logger.get('string_sizing');

export class StringingError extends FLError {}

const STC_VOLTAGE = 25.0; // standard test condition voltage is 25ºC - this is what module voltages are relative to

// percentage point decrease in voltage for every degree above stc voltage,
// divided by 100 to get percent
const DEFAULT_TEMP_VOLTAGE = -0.35;


/**
 * default for string calculations where weather data is unavailable
 * wanted to pick something that looks synthetic but generally tracks
 * to real data.
 *
 * Mean Low Temp: -11.5ºC (Tupelo C D Lemons Arpt MS USA)
 * Mean Max Temp: 34.1ºC (Botosani Romania)
 * Mean Spread = 33.1 - (-15.2) = 48.3ºC (Idar-Oberstein Germany)
 */
export const DEFAULT_ASHRAE_RANGE = {

    // these are the props that woudl come from the database
    name: 'Default Extremes',
    min_temp_extreme_mean: -10,
    max_temp_extreme_mean: 40,

    // to make it easier to use both angular and react, just create stubs for
    // the object properties rather than initializing an instance of AshraeWeather
    minTemp: -10,
    maxTemp: 40,
    toString() {
        return 'Default Extremes (ASHRAE)';
    },
};

/**
 * calculate the relevant module voltages at min and max temperatures
 * vMpMaxTemp: the voltage a module will produce peak power at the max temp.
 *             Important because modules lose their voltage as temp rises, so
 *             need to know how modules will perform under load
 *
 * vOcMinTemp: the absolute maximum voltage a module can output, module
 *             voltage increases as temperature decreases, so this is critical
 *             to make sure that voltage doesn't exceed regulatory limits
 *             during the winter
 */
export function moduleVoltagesAtTemp(moduleCharacterization, { minTemp, maxTemp }) {
    const {
        v_oc: vOc, // open circuit (max) voltage of a module
        v_mp: vMp, // max power voltage of a module
    } = moduleCharacterization;

    // tempVoltage represents the voltage change % per degree deviation
    // from STC_VOLTAGE
    const tempVoltage = (moduleCharacterization.temp_voltage || DEFAULT_TEMP_VOLTAGE) / 100;

    return {
        vOc,
        vMp,
        vMpMaxTemp: vMp * (1 + tempVoltage * (maxTemp - STC_VOLTAGE)),
        vOcMinTemp: vOc * (1 + tempVoltage * (minTemp - STC_VOLTAGE)),
    };
}

/*
More info: https://github.com/aurorasolar/helioscope/issues/501

Ruleset for translating max_voltage (from power_device) to max Voc:
[max_voltage] => [max Voc]
    <250 => max_voltage
    250-600 => 600
    601-699 => max_voltage
    700-1000 => 1000
    1001-1199 => max_voltage
    1200-1500 => 1500
    greater than 1500 => max_voltage
*/
function inverterMaxVoc(inverter, additionalMax = 0) {
    const maxV = Math.max(inverter.max_voltage || 0, inverter.max_mpp_voltage || 0, additionalMax);
    if (maxV < 250) {
        return maxV;
    }
    if (maxV <= 600) {
        return 600;
    }
    if (maxV <= 699) {
        return maxV;
    }
    if (maxV <= 1000) {
        return 1000;
    }
    if (maxV <= 1199) {
        return maxV;
    }
    if (maxV <= 1500) {
        return 1500;
    }
    return maxV;
}

/**
 * parse inverter operating bounds for the array.
 *
 * min/maxVoltage are the ranges where the inverter can produce peak power
 * code max voltage is the maximum voltage allowed on the array by code
 */
function getInverterLimits(inverter, CODE_MAX_VOLTAGE = 600) {
    return {
        minVoltage: inverter.min_mpp_voltage,
        maxVoltage: inverter.max_mpp_voltage,
        dcVoltageLimit: inverterMaxVoc(inverter, CODE_MAX_VOLTAGE),
    };
}

/**
 * get min and max allowable counts for modules that respect code and ideally
 * ensure that modules are always producing within the inverters operating
 * threshold
 */
export function calculateBoundsFromTemp(moduleVoltages, inverterLimits) {
    const {
        minVoltage,
        maxVoltage,
        dcVoltageLimit,
    } = inverterLimits;

    const minMpp = Math.ceil(minVoltage / moduleVoltages.vMpMaxTemp);
    const maxMpp = Math.floor(maxVoltage / moduleVoltages.vMpMaxTemp);
    const hardMax = Math.floor(dcVoltageLimit / moduleVoltages.vOcMinTemp);

    return {
        min: Math.min(minMpp, hardMax),
        max: Math.min(maxMpp, hardMax),
        minWarning: 'Low Vmp at High Temperatures',
        maxWarning: 'High Voc at Low Temperatures',
        moduleVoltages,
        inverterLimits,
    };
}

/**
 * methods for scrubbing and returning manually entered stringing bounds from a wiring zone
 *
 * if either of the manual fields are set, assume manual
 */
export const UserDefined = {
    available(wiringZone) {
        return !(lodash.isNil(wiringZone.string_size_max) && lodash.isNil(wiringZone.string_size_min));
    },
    determineBounds(wiringZone) {
        let {
            string_size_min: min,
            string_size_max: max,
        } = wiringZone;

        if (lodash.isNil(min)) min = max;
        if (lodash.isNil(max)) max = min;

        return {
            source: 'manual',
            min: Math.min(min, max),
            max: Math.max(min, max),
        };
    },
};


export function getMostImportantModuleCharacterization(wiringZone) {
    if (wiringZone.workingModuleCharacterization) return wiringZone.workingModuleCharacterization();

    const largestFieldSegment = lodash.maxBy(wiringZone.field_segments, fs => fs.moduleCount());
    return lodash.get(largestFieldSegment, 'module_characterization');
}

function getDesignData(wiringZone) {
    const {
        inverter,
        power_optimizer: powerOptimizer,
    } = wiringZone;
    const moduleCharacterization = getMostImportantModuleCharacterization(wiringZone);

    if (!moduleCharacterization || !inverter) {
        throw new StringingError(
            'components missing', {
                noModule: moduleCharacterization === undefined,
                noInverter: inverter === undefined,
            },
        );
    }

    let ashraeWeather = wiringZone.design.project.ashrae_weather;

    if (!ashraeWeather) {
        logger.info('No ASHRAE weather for location');
        ashraeWeather = DEFAULT_ASHRAE_RANGE;
    }

    return {
        inverter, moduleCharacterization, powerOptimizer, ashraeWeather,
    };
}


/**
 * get defined string bounds for a wiring zone
 */
export function determineBounds(wiringZone, inputConfiguration = wiringZone.inputConfiguration()) {
    const { inverter, powerOptimizer, moduleCharacterization, ashraeWeather } = getDesignData(wiringZone);

    let source;
    let bounds;
    let baseSource;

    const solaredgeSystem = solaredge.isAvailable(inverter, powerOptimizer);
    const createSchedule = solaredgeSystem ? solaredge.createSchedule : scheduling.createInverterSchedule;

    if (solaredgeSystem) {
        baseSource = {
            source: 'solaredge',
            bounds: solaredge.determineBounds(inverter, powerOptimizer, moduleCharacterization, inputConfiguration),
        };
    } else {
        baseSource = {
            source: 'temperature',
            weather: ashraeWeather,
            bounds: calculateBoundsFromTemp(
                moduleVoltagesAtTemp(moduleCharacterization, ashraeWeather),
                getInverterLimits(inverter),
            ),
        };
    }

    if (UserDefined.available(wiringZone)) {
        source = 'manual';
        bounds = UserDefined.determineBounds(wiringZone);
    } else {
        source = baseSource.source;
        bounds = baseSource.bounds;
    }

    return {
        source,

        // included for validation/tooltips to reference the calculated values
        // for the purpose of validation when the user overrides
        baseSource,

        // not in love with looping this in here, but wanted the selection
        // logic for schedule creation to align with the logic for bounds creation
        createSchedule,

        ...bounds,
    };
}
