/**
 * Predeclared messages file for react-intl
 *
 * The `defineMessages` call is so that all messages can be parsed out via AST for translation.
 */

import { defineMessages } from 'react-intl';

const CONSTANT_STRINGS = defineMessages({
    january: {
        id: 'constants.january',
        description: 'January',
        defaultMessage: 'January',
    },
    february: {
        id: 'constants.february',
        description: 'February',
        defaultMessage: 'February',
    },
    march: {
        id: 'constants.march',
        description: 'March',
        defaultMessage: 'March',
    },
    april: {
        id: 'constants.april',
        description: 'April',
        defaultMessage: 'April',
    },
    may: {
        id: 'constants.may',
        description: 'May',
        defaultMessage: 'May',
    },
    june: {
        id: 'constants.june',
        description: 'June',
        defaultMessage: 'June',
    },
    july: {
        id: 'constants.july',
        description: 'July',
        defaultMessage: 'July',
    },
    august: {
        id: 'constants.august',
        description: 'August',
        defaultMessage: 'August',
    },
    september: {
        id: 'constants.september',
        description: 'September',
        defaultMessage: 'September',
    },
    october: {
        id: 'constants.october',
        description: 'October',
        defaultMessage: 'October',
    },
    november: {
        id: 'constants.november',
        description: 'November',
        defaultMessage: 'November',
    },
    december: {
        id: 'constants.december',
        description: 'December',
        defaultMessage: 'December',
    },
    january_initial: {
        id: 'constants.january_initial',
        description: 'January initial',
        defaultMessage: 'J',
    },
    february_initial: {
        id: 'constants.february_initial',
        description: 'February initial',
        defaultMessage: 'F',
    },
    march_initial: {
        id: 'constants.march_initial',
        description: 'March initial',
        defaultMessage: 'M',
    },
    april_initial: {
        id: 'constants.april_initial',
        description: 'April initial',
        defaultMessage: 'A',
    },
    may_initial: {
        id: 'constants.may_initial',
        description: 'May initla',
        defaultMessage: 'M',
    },
    june_initial: {
        id: 'constants.june_initial',
        description: 'J',
        defaultMessage: 'J',
    },
    july_initial: {
        id: 'constants.july_initial',
        description: 'July initial',
        defaultMessage: 'J',
    },
    august_initial: {
        id: 'constants.august_initial',
        description: 'August initial',
        defaultMessage: 'A',
    },
    september_initial: {
        id: 'constants.september_initial',
        description: 'September initial',
        defaultMessage: 'S',
    },
    october_initial: {
        id: 'constants.october_initial',
        description: 'October initial',
        defaultMessage: 'O',
    },
    november_initial: {
        id: 'constants.november_initial',
        description: 'November initial',
        defaultMessage: 'N',
    },
    december_initial: {
        id: 'constants.december_initial',
        description: 'December initial',
        defaultMessage: 'D',
    },
    january_abbrev: {
        id: 'constants.january_abbrev',
        description: 'January abbrev',
        defaultMessage: 'Jan',
    },
    february_abbrev: {
        id: 'constants.february_abbrev',
        description: 'February abbrev',
        defaultMessage: 'Feb',
    },
    march_abbrev: {
        id: 'constants.march_abbrev',
        description: 'March abbrev',
        defaultMessage: 'Mar',
    },
    april_abbrev: {
        id: 'constants.april_abbrev',
        description: 'April abbrev',
        defaultMessage: 'Apr',
    },
    may_abbrev: {
        id: 'constants.may_abbrev',
        description: 'May initla',
        defaultMessage: 'May',
    },
    june_abbrev: {
        id: 'constants.june_abbrev',
        description: 'J',
        defaultMessage: 'Jun',
    },
    july_abbrev: {
        id: 'constants.july_abbrev',
        description: 'July abbrev',
        defaultMessage: 'Jul',
    },
    august_abbrev: {
        id: 'constants.august_abbrev',
        description: 'August abbrev',
        defaultMessage: 'Aug',
    },
    september_abbrev: {
        id: 'constants.september_abbrev',
        description: 'September abbrev',
        defaultMessage: 'Sep',
    },
    october_abbrev: {
        id: 'constants.october_abbrev',
        description: 'October abbrev',
        defaultMessage: 'Oct',
    },
    november_abbrev: {
        id: 'constants.november_abbrev',
        description: 'November abbrev',
        defaultMessage: 'Nov',
    },
    december_abbrev: {
        id: 'constants.december_abbrev',
        description: 'December abbrev',
        defaultMessage: 'Dec',
    },
    southwest: {
        id: 'constants.southwest',
        description: 'Southwest (cardinal direction) (used in DesignSnapshotForm)',
        defaultMessage: 'Southwest',
    },
    southeast: {
        id: 'constants.southeast',
        description: 'Southeast (cardinal direction) (used in DesignSnapshotForm)',
        defaultMessage: 'Southeast',
    },
});

export default CONSTANT_STRINGS;
