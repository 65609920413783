import Logger from 'js-logger';

import { flagWindowUnload, unflagWindowUnload } from 'reports/components/helpers/common';
import { AsyncThunk, Deferred } from 'reports/utils/async_helpers';
import { DeepPartial } from 'reports/utils/types';

import { SchemaObject, getPatchId, patchCreated, patchesRemoved, IPatch, patchMerge } from './Schema';

const logger = Logger.get('saver');
const DEFAULT_DEBOUNCE = 2500; // debounce saves by 2.5 seconds

interface ICommitFn<I> {
    (obj: I | any): AsyncThunk<I, any>;
}

interface IAsyncPatch<I = any> {
    patch: IPatch<I>;
    status: 'pending' | 'inflight' | 'success' | 'error';
}

class SaveQueue<I> {
    saver: (patches: IPatch[]) => Promise<I>;

    patchQueue: IAsyncPatch<I>[] = [];

    // return null if nothing to save on a flush, or if nothing was saved (ie the state was cleared)
    deferredFlush = new Deferred<I | null>();
    flushing: boolean = false;

    timeoutId: number;

    constructor(saver: (patches: IPatch[]) => Promise<I | any>) {
        this.saver = saver;
    }

    get isEmpty() {
        return this.patchQueue.length === 0;
    }

    addPatch(patch) {
        this.patchQueue.push({ patch, status: 'pending' });
    }

    clearPatches() {
        if (this.flushing) {
            throw Error('Save in process, could not clear patches');
        }

        if (this.patchQueue.length) {
            this.patchQueue = [];
        }
        this.deferredFlush.resolve(null);
        return this.deferredFlush.promise;
    }

    filteredPatches(status) {
        return this.patchQueue.filter((x) => x.status === status);
    }

    async flushPatches() {
        if (this.flushing) {
            return this.deferredFlush.promise;
        }

        this.clearTimer();
        this.flushing = true;

        let lastResult: I | null = null;

        while (this.filteredPatches('pending').length > 0) {
            const patches = this.filteredPatches('pending');
            patches.forEach((x) => (x.status = 'inflight'));

            try {
                lastResult = await this.saver(patches.map((x) => x.patch));
                patches.forEach((x) => (x.status = 'success'));
                this.deferredFlush.resolve(lastResult); // this will be null if there were no pending patches
            } catch (err) {
                patches.forEach((x) => (x.status = 'error'));
                logger.warn(`Error saving patches`);
                this.deferredFlush.reject(err);
            }
        }

        this.flushing = false;

        return this.deferredFlush.promise;
    }

    clearTimer() {
        if (this.timeoutId != null) {
            clearTimeout(this.timeoutId);
        }
    }

    scheduleSave(debounce: number) {
        this.clearTimer();

        if (debounce > -1) {
            this.timeoutId = setTimeout(() => this.flushPatches(), debounce) as any as number;
        }

        return this.deferredFlush.promise;
    }
}

export function createAsyncSaver<I, State = any>(
    schemaObject: SchemaObject<I, State>,
    commitFn: ICommitFn<I>,
    debounce: number = DEFAULT_DEBOUNCE,
) {
    const savers: { [k: string]: SaveQueue<I> } = {};

    return {
        get(obj: I | any) {
            const objKey = schemaObject.objectKey(obj);
            const flagKey = `saving!${objKey}`;

            // Get or create new saver
            function getSaver(dispatch, getState) {
                let saver = savers[objKey];

                if (saver == null) {
                    logger.info(`Creating saver ${objKey}`);

                    saver = savers[objKey] = new SaveQueue(async (patches: IPatch[]) => {
                        const schemaState = schemaObject.schema.schemaState(getState());
                        const unpatched = schemaObject.selectRawById(schemaState, schemaObject.objectId(obj), false);
                        const fullPatch = patchMerge(
                            unpatched,
                            patches.map((x) => x.delta),
                        );

                        // TODO: if the commit fails, it will throw an error
                        // which the saver will never recover from
                        const result = await dispatch(commitFn(fullPatch));

                        // Remove patches directly from the saver so that rerenders will have the correct data -
                        // this means savers will never store 'success' patches at rest
                        saver.patchQueue = saver.patchQueue.filter(({ patch }) => !patches.includes(patch));

                        dispatch(
                            patchesRemoved({
                                patches,
                                objectId: schemaObject.objectId(obj),
                                schema: schemaObject,
                            }),
                        );

                        return result;
                    });

                    saver.deferredFlush.promise
                        .then((res) => {
                            logger.info(`Finished saving ${objKey}`, res);
                        })
                        .catch((err) => {
                            logger.warn(`Error updating ${objKey}`, err);
                        })
                        .then(() => {
                            // this always runs
                            unflagWindowUnload(flagKey);
                            delete savers[objKey];
                        });
                }

                return saver;
            }

            return {
                save: () => async (dispatch, getState) => getSaver(dispatch, getState).flushPatches(),
                patch:
                    (delta: DeepPartial<I> | any = obj, saveNow: boolean = false) =>
                    async (dispatch, getState) => {
                        flagWindowUnload(flagKey);

                        const cleanedDelta = schemaObject.pruneRelationships(delta);
                        const patch = {
                            schema: schemaObject,
                            objectId: schemaObject.objectId(obj),
                            patchId: getPatchId(),
                            delta: cleanedDelta,
                        };
                        const saver = getSaver(dispatch, getState);

                        dispatch(patchCreated(patch));
                        saver.addPatch(patch);

                        if (saveNow) {
                            logger.info(`Flushing changes for ${objKey}`);
                            return saver.flushPatches();
                        }

                        logger.info(`Scheduling a save for ${objKey}`);
                        return saver.scheduleSave(debounce);
                    },
                clear: () => async (dispatch, getState) => {
                    unflagWindowUnload(flagKey);

                    const saver = getSaver(dispatch, getState);

                    dispatch(
                        patchesRemoved({
                            schema: schemaObject,
                            objectId: schemaObject.objectId(obj),
                        }),
                    );

                    logger.info(`Clearing patches for ${objKey}`);
                    return saver.clearPatches();
                },
                hasChanges: () => (dispatch, getState) => !getSaver(dispatch, getState).isEmpty,
            };
        },
    };
}

export default createAsyncSaver;
