/**
 * Predeclared messages file for react-intl
 *
 * The `defineMessages` call is so that all messages can be parsed out via AST for translation.
 */

import { defineMessages } from 'react-intl';

const SIMULATION_STRINGS = defineMessages({
    annual_production: {
        id: 'simulation.annual_production',
        description: 'Annual Production (i.e. solar energy produced)',
        defaultMessage: 'Annual Production',
    },
    ghi: {
        id: 'simulation.ghi',
        description: 'GHI (Global Horizontal Irradiance)',
        defaultMessage: 'GHI',
    },
    poa: {
        id: 'simulation.poa',
        description: 'POA',
        defaultMessage: 'POA',
    },
    shaded: {
        id: 'simulation.shaded',
        description: 'Shaded (as in kWh/m^2 shading loss)',
        defaultMessage: 'Shaded',
    },
    grid: {
        id: 'simulation.grid',
        description: 'Grid (i.e. solar power to grid)',
        defaultMessage: 'Grid',
    },
    annual_global_horiz_irrad: {
        id: 'simulation.annual_global_horiz_irrad',
        description: 'Annual Global Horizontal Irradiance',
        defaultMessage: 'Annual Global Horizontal Irradiance',
    },
    annual_horiz_irrad_tooltip: {
        id: 'simulation.annual_horiz_irrad_tooltip',
        description: 'Annual Global Horizontal Irradiance tooltip',
        defaultMessage: 'Total global horizontal irradiance for the year, directly from the weather data.',
    },
    adj_global_horiz_irrad: {
        id: 'simulation.adj_global_horiz_irrad',
        description: 'Adjusted Global Horizontal Irradiance',
        defaultMessage: 'Adjusted Global Horizontal Irradiance',
    },
    adj_horiz_irrad_tooltip: {
        id: 'simulation.adj_horiz_irrad_tooltip',
        description: 'Adjusted Global Horizontal Irradiance tooltip',
        defaultMessage: 'Total global horizontal irradiance for the year, directly from the weather data.',
    },
    poa_irradiance_tooltip: {
        id: 'simulation.poa_irradiance_tooltip',
        description: 'POA Irradiance tooltip',
        defaultMessage: `Total irradiance in the plane of the array of the modules (averaged across all modules
            and field segments).`,
    },
    poa_irradiance: {
        id: 'simulation.poa_irradiance',
        description: 'POA Irradiance (solar design simulation)',
        defaultMessage: 'POA Irradiance',
    },
    shaded_irradiance: {
        id: 'simulation.shaded_irradiance',
        description: 'Shaded Irradiance',
        defaultMessage: 'Shaded Irradiance',
    },
    shaded_irradiance_tooltip: {
        id: 'simulation.shaded_irradiance_tooltip',
        description: 'Shaded Irradiance Tooltip',
        defaultMessage: 'Irradiance after all shading is accounted for (horizon, cross-bank, obstructions).',
    },
    irradiance_after_reflection: {
        id: 'simulation.irradiance_after_reflection',
        description: 'Irradiance After Reflection (solar design simulation)',
        defaultMessage: 'Irradiance After Reflection',
    },
    irradiance_after_reflection_tooltip: {
        id: 'simulation.irradiance_after_reflection_tooltip',
        description: 'Irradiance After Reflection Tooltip',
        defaultMessage: 'Irradiance after reflection off the surface of the module (i.e. IAM reflection).',
    },
    irradiance_after_soiling: {
        id: 'simulation.irradiance_after_soiling',
        description: 'Irradiance After Soiling (solar design simulation)',
        defaultMessage: 'Irradiance After Soiling',
    },
    irradiance_after_soiling_tooltip: {
        id: 'simulation.irradiance_after_soiling_tooltip',
        description: 'Irradiance After Soiling Tooltip',
        defaultMessage: 'Irradiance after module soiling (e.g. surface dust) is accounted for.',
    },
    total_collector_irradiance: {
        id: 'simulation.total_collector_irradiance',
        description: 'Total Collector Irradiance (solar design simulation)',
        defaultMessage: 'Total Collector Irradiance',
    },
    total_collector_irradiance_tooltip: {
        id: 'simulation.total_collector_irradiance_tooltip',
        description: 'Total Collector Irradiance tooltip',
        defaultMessage: `Total annual irradiance available to the modules in the array (averaged across all
            modules and field segments).`,
    },
    module_irradiance_derated_power: {
        id: 'simulation.module_irradiance_derated_power',
        description: 'Output at Irradiance Levels (solar design simulation)',
        defaultMessage: 'Output at Irradiance Levels',
    },
    irradiance_derated_power_tooltip: {
        id: 'simulation.irradiance_derated_power_tooltip',
        description: 'Tooltip for Output at Irradiance Levels',
        defaultMessage: `Total energy output by the modules, after taking into account low light levels and module I/V
            curve effects.`,
    },
    module_mpp_power: {
        id: 'simulation.module_mpp_power',
        description: 'Output at Cell Temperature Derate (solar design simulation)',
        defaultMessage: 'Output at Cell Temperature Derate',
    },
    module_mpp_power_tooltip: {
        id: 'simulation.module_mpp_power_tooltip',
        description: 'Output at Cell Temperature Derate tooltip',
        defaultMessage: `Total energy output by the modules, after temperature effects on module I/V curves.
            This is the output of the modules at their Maximum Power Point.`,
    },
    module_mismatch: {
        id: 'simulation.module_mismatch',
        description: 'Output After Mismatch (solar design simulation)',
        defaultMessage: 'Output After Mismatch',
    },
    module_mismatch_tooltip: {
        id: 'simulation.module_mismatch_tooltip',
        description: 'Output After Mismatch tooltip',
        defaultMessage: 'Total energy output by the modules, including stringing and mismatch effects.',
    },
    optimizer_output_power: {
        id: 'simulation.optimizer_output_power',
        description: 'Optimizer Output (solar design simulation)',
        defaultMessage: 'Optimizer Output',
    },
    optimizer_output_power_tooltip: {
        id: 'simulation.optimizer_output_power_tooltip',
        description: 'Optimizer Output Tooltip',
        defaultMessage: `If the array has module level electronics, this is the output of those optimizers
            after taking into account the efficiency curve and principles of operation for the
            optimizer used in the design.`,
    },
    optimal_dc_output: {
        id: 'simulation.optimal_dc_output',
        description: 'Optimal DC Output (solar design simulation)',
        defaultMessage: 'Optimal DC Output',
    },
    optimal_dc_output_tooltip: {
        id: 'simulation.optimal_dc_output_tooltip',
        description: 'Optimal DC Output Tooltip',
        defaultMessage: `Total energy output by the DC subsystem in the array, taking into account all
            resistive wire losses.`,
    },
    constrained_dc_output: {
        id: 'simulation.constrained_dc_output',
        description: 'Constrained DC Output (solar design simulation)',
        defaultMessage: 'Constrained DC Output',
    },
    constrained_dc_output_tooltip: {
        id: 'simulation.constrained_dc_output_tooltip',
        description: 'Constrained DC Output Tooltip',
        defaultMessage: 'Total energy output of the DC array, taking into account inverter clipping.',
    },
    overvoltage: {
        id: 'simulation.overvoltage',
        description: 'Overvoltage (solar design simulation)',
        defaultMessage: 'Overvoltage',
    },
    undervoltage: {
        id: 'simulation.undervoltage',
        description: 'Undervoltage (solar design simulation)',
        defaultMessage: 'Undervoltage',
    },
    overpower: {
        id: 'simulation.overpower',
        description: 'Overpower (solar design simulation)',
        defaultMessage: 'Overpower',
    },
    underpower: {
        id: 'simulation.underpower',
        description: 'Underpower (solar design simulation)',
        defaultMessage: 'Underpower',
    },
    actual_dc_power: {
        id: 'simulation.actual_dc_power',
        description: 'Actual DC Output (solar design simulation)',
        defaultMessage: 'Actual DC Output',
    },
    actual_dc_power_tooltip: {
        id: 'simulation.actual_dc_power_tooltip',
        description: 'Actual DC Output Tooltip',
        defaultMessage: 'Total energy output of the DC array, including all resistive wire losses.',
    },
    ac_power: {
        id: 'simulation.ac_power',
        description: 'Inverter Output (solar design simulation)',
        defaultMessage: 'Inverter Output',
    },
    ac_power_tooltip: {
        id: 'simulation.ac_power_tooltip',
        description: 'Inverter Output Tooltip',
        defaultMessage: `Total energy output from all inverters, taking into account inverter efficiency
            and performance characteristics.`,
    },
    grid_power: {
        id: 'simulation.grid_power',
        description: 'Energy to Grid (solar design simulation)',
        defaultMessage: 'Energy to Grid',
    },
    grid_power_tooltip: {
        id: 'simulation.grid_power_tooltip',
        description: 'Energy to Grid tooltip',
        defaultMessage: 'Total energy injected into the grid after AC system losses.',
    },
    irradiance: {
        id: 'simulation.irradiance',
        description: 'Irradiance (solar design simulation)',
        defaultMessage: 'Irradiance',
    },
    energy: {
        id: 'simulation.energy',
        description: 'Energy (solar design simulation)',
        defaultMessage: 'Energy',
    },
    output: {
        id: 'simulation.output',
        description: 'Output (as in energy output)',
        defaultMessage: 'Output',
    },
    percent_delta: {
        id: 'simulation.percent_delta',
        description: 'Percent Delta (difference between nameplate and actual output)',
        defaultMessage: '% Delta',
    },
    temperature_metrics: {
        id: 'simulation.temperature_metrics',
        description: 'Temperature Metrics (solar design simulation)',
        defaultMessage: 'Temperature Metrics',
    },
    avg_ambient_temp: {
        id: 'simulation.avg_ambient_temp',
        description: 'Avg. Operating Ambient Temp (solar design simulation)',
        defaultMessage: 'Avg. Operating Ambient Temp',
    },
    avg_cell_temp: {
        id: 'simulation.avg_cell_temp',
        description: 'Avg. Operating Cell Temp (solar design simulation)',
        defaultMessage: 'Avg. Operating Cell Temp',
    },
    simulation_metrics: {
        id: 'simulation.simulation_metrics',
        description: 'Simulation Metrics (solar design simulation)',
        defaultMessage: 'Simulation Metrics',
    },
    operating_hours: {
        id: 'simulation.operating_hours',
        description: 'Operating Hours (solar design simulation)',
        defaultMessage: 'Operating Hours',
    },
    solved_hours: {
        id: 'simulation.solved_hours',
        description: 'Solved Hours (solar design simulation)',
        defaultMessage: 'Solved Hours',
    },
    pending_hours: {
        id: 'simulation.pending_hours',
        description: 'Pending Hours (solar design simulation)',
        defaultMessage: 'Pending Hours',
    },
    error_hours: {
        id: 'simulation.error_hours',
        description: 'Error Hours (solar design simulation)',
        defaultMessage: 'Error Hours',
    },
    ac_energy: {
        id: 'simulation.ac_energy',
        description: 'AC Energy',
        defaultMessage: 'AC Energy',
    },
    tof: {
        id: 'simulation.tof',
        description: 'TOF^2 (Tilt-Orientation Factor squared)',
        defaultMessage: 'TOF<sup>2</sup>',
    },
    tilt_orientation_factor: {
        id: 'simulation.tilt_orientation_factor',
        description: 'Tilt-Orientation Factor',
        defaultMessage: 'Tilt-Orientation Factor',
    },
    solar_access: {
        id: 'simulation.solar_access',
        description: 'Solar Access',
        defaultMessage: 'Solar Access',
    },
    minimum_solar_resource_fraction: {
        id: 'simulation.minimum_solar_resource_fraction',
        description: 'Minimum Solar Resource Fraction (for a module)',
        defaultMessage: 'Minimum Solar Resource Fraction (for a module)',
    },
    min_tsrf: {
        id: 'simulation.min_tsrf',
        description: 'Min TSRF^2 (Total Solar Resource Fraction squared)',
        defaultMessage: 'Min TSRF<sup>2</sup>',
    },
    total_solar_resource_fraction: {
        id: 'simulation.total_solar_resource_fraction',
        description: 'Total Solar Resource Fraction',
        defaultMessage: 'Total Solar Resource Fraction',
    },
    avg_tsrf: {
        id: 'simulation.avg_tsrf',
        description: 'Avg TSRF^2 (Total Solar Resource Fraction squared)',
        defaultMessage: 'Avg TSRF<sup>2</sup>',
    },
    totals_weighted_by_kwp: {
        id: 'simulation.totals_weighted_by_kwp',
        description: 'Totals, weighted By kWp',
        defaultMessage: 'Totals, weighted By kWp',
    },
    perf_ratio: {
        id: 'simulation.perf_ratio',
        description: 'solar energy Performance Ratio',
        defaultMessage: 'Performance Ratio',
    },
    kwh_kwp: {
        id: 'simulation.kwh_kwp',
        description: 'kWh/kWp',
        defaultMessage: 'kWh/kWp',
    },
    p90: {
        id: 'simulation.p90',
        description: 'P90 Output (for solar)',
        defaultMessage: 'P90 Output (kWh/kWp)',
    },
    annual_production_tooltip: {
        id: 'simulation.annual_production_tooltip',
        description: 'Annual Production tooltip content',
        defaultMessage: 'Total annual energy output of the system.',
    },
    perf_ratio_tooltip: {
        id: 'simulation.perf_ratio_tooltip',
        description: 'Performance Ratio tooltip content',
        defaultMessage: 'System efficiency in converting sunlight to grid-available AC energy.',
    },
    output_ratio_tooltip: {
        id: 'simulation.output_ratio_tooltip',
        description: 'Output Energy Ratio tooltip content',
        defaultMessage: 'Ratio of output energy to relative to module (DC) nameplate power.',
    },
    p90_tooltip: {
        id: 'simulation.p90_tooltip',
        description: 'P90 tooltip content',
        defaultMessage: 'P90 estimate of output energy to relative to module (DC) nameplate power.',
    },
    simulator_version: {
        id: 'simulation.simulator_version',
        description: 'Simulator Version',
        defaultMessage: 'Simulator Version',
    },
    production_kwh: {
        id: 'simulation.production_kwh',
        description: 'Production (kWh)',
        defaultMessage: 'Production (kWh)',
    },
    consumption_kwh: {
        id: 'simulation.consumption_kwh',
        description: 'Consumption (kWh)',
        defaultMessage: 'Consumption (kWh)',
    },
    shading_loss_name: {
        id: 'simulation.shading_loss.name',
        description: 'name of Shading Loss section (used in System Loss Chart)',
        defaultMessage: 'Shading',
    },
    shading_loss_description: {
        id: 'simulation.shading_loss.description',
        description: 'description of Shading Loss section (used in System Loss Chart)',
        defaultMessage: 'Irradiance loss due to shading',
    },
    reflection_loss_name: {
        id: 'simulation.reflection_loss.name',
        description: 'name of Reflection Loss section (used in System Loss Chart)',
        defaultMessage: 'Reflection',
    },
    reflection_loss_description: {
        id: 'simulation.reflection_loss.description',
        description: 'description of Reflection Loss section (used in System Loss Chart)',
        defaultMessage: 'Irradiance loss due to reflection',
    },
    soiling_loss_name: {
        id: 'simulation.soiling_loss.name',
        description: 'name of Soiling Loss section (used in System Loss Chart)',
        defaultMessage: 'Soiling',
    },
    soiling_loss_description: {
        id: 'simulation.soiling_loss.description',
        description: 'description of Soiling Loss section (used in System Loss Chart)',
        defaultMessage: 'Irradiance loss due to soiling',
    },
    low_irradiation_loss_name: {
        id: 'simulation.low_irradiation_loss.name',
        description: 'name of Low Irradiation Loss section (used in System Loss Chart)',
        defaultMessage: 'Irradiance',
    },
    low_irradiation_loss_description: {
        id: 'simulation.low_irradiation_loss.description',
        description: 'description of Low Irradiation Loss section (used in System Loss Chart)',
        defaultMessage: 'Module low-irradiation power loss',
    },
    temperature_loss_name: {
        id: 'simulation.temperature_loss.name',
        description: 'name of Temperature Loss section (used in System Loss Chart)',
        defaultMessage: 'Temperature',
    },
    temperature_loss_description: {
        id: 'simulation.temperature_loss.description',
        description: 'description of Temperature Loss section (used in System Loss Chart)',
        defaultMessage: 'Module temperature power loss',
    },
    mismatch_loss_name: {
        id: 'simulation.mismatch_loss.name',
        description: 'name of Mismatch Loss section (used in System Loss Chart)',
        defaultMessage: 'Mismatch',
    },
    mismatch_loss_description: {
        id: 'simulation.mismatch_loss.description',
        description: 'description of Mismatch Loss section (used in System Loss Chart)',
        defaultMessage: 'Power loss due to mismatch',
    },
    optimizer_loss_name: {
        id: 'simulation.optimizer_loss.name',
        description: 'name of Optimizer Loss section (used in System Loss Chart)',
        defaultMessage: 'Optimizer',
    },
    optimizer_loss_description: {
        id: 'simulation.optimizer_loss.description',
        description: 'description of Optimizer Loss section (used in System Loss Chart)',
        defaultMessage: 'Power loss due to optimizer efficiency',
    },
    wiring_loss_name: {
        id: 'simulation.wiring_loss.name',
        description: 'name of Wiring Loss section (used in System Loss Chart)',
        defaultMessage: 'Wiring',
    },
    wiring_loss_description: {
        id: 'simulation.wiring_loss.description',
        description: 'description of Wiring Loss section (used in System Loss Chart)',
        defaultMessage: 'DC wiring I<sup>2</sup>R loss',
    },
    clipping_loss_name: {
        id: 'simulation.clipping_loss.name',
        description: 'name of Clipping Loss section (used in System Loss Chart)',
        defaultMessage: 'Clipping',
    },
    clipping_loss_description: {
        id: 'simulation.clipping_loss.description',
        description: 'description of Clipping Loss section (used in System Loss Chart)',
        defaultMessage: 'Power loss due to inverter input voltage and power limits',
    },
    inverter_loss_name: {
        id: 'simulation.inverter_loss.name',
        description: 'name of Inverter Loss section (used in System Loss Chart)',
        defaultMessage: 'Inverter',
    },
    inverter_loss_description: {
        id: 'simulation.inverter_loss.description',
        description: 'description of Inverter Loss section (used in System Loss Chart)',
        defaultMessage: 'Power loss due to inverter efficiency',
    },
    ac_system_loss_name: {
        id: 'simulation.ac_system_loss.name',
        description: 'name of AC System Loss section (used in System Loss Chart)',
        defaultMessage: 'AC System',
    },
    ac_system_loss_description: {
        id: 'simulation.ac_system_loss.description',
        description: 'description of AC System Loss section (used in System Loss Chart)',
        defaultMessage: 'Power loss due to AC Conductors and Transformers',
    },
});

export default SIMULATION_STRINGS;
