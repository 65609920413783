/**
 * Predeclared messages file for react-intl
 *
 * The `defineMessages` call is so that all messages can be parsed out via AST for translation.
 */

import { defineMessages } from 'react-intl';

const GENERAL_STRINGS = defineMessages({
    description: {
        id: 'general.description',
        description: 'Description',
        defaultMessage: 'Description',
    },
    quantity: {
        id: 'general.quantity',
        description: 'Quantity',
        defaultMessage: 'Quantity',
    },
    month: {
        id: 'general.month',
        description: 'Month',
        defaultMessage: 'Month',
    },
    year: {
        id: 'general.year',
        description: 'Year',
        defaultMessage: 'Year',
    },
    cancel: {
        id: 'general.cancel',
        description: 'Cancel button',
        defaultMessage: 'Cancel',
    },
    create_screenshot: {
        id: 'general.create_screenshot',
        description: 'Create Screenshot button',
        defaultMessage: 'Create Screenshot',
    },
    location: {
        id: 'general.location',
        description: 'Location',
        defaultMessage: 'Location',
    },
    tz_offset: {
        id: 'general.tz_offset',
        description: 'Timezone Offset',
        defaultMessage: 'GMT {offset}',
    },
    settings: {
        id: 'general.settings',
        description: 'Label for settings (used in DesignSnapshotForm)',
        defaultMessage: 'Settings',
    },
    elevation: {
        id: 'general.elevation',
        description: 'Elevation (as in camera elevation) (used in DesignSnapshotForm)',
        defaultMessage: 'Elevation',
    },
    zoom: {
        id: 'general.zoom',
        description: 'Zoom (as in camera zoom) (used in DesignSnapshotForm)',
        defaultMessage: 'Zoom',
    },
    projection: {
        id: 'general.projection',
        description: 'Projection (as in Camera Projection) (used in DesignSnapshotForm)',
        defaultMessage: 'Projection',
    },
    orthographic: {
        id: 'general.projection.orthographic',
        description: 'Orthographic (as in orthographic projection) (used in DesignSnapshotForm)',
        defaultMessage: 'Orthographic',
    },
    perspective: {
        id: 'general.projection.perspective',
        description: 'Perspective (as in perspective projection) (used in DesignSnapshotForm)',
        defaultMessage: 'Perspective',
    },
    advanced: {
        id: 'general.advanced',
        description: 'Advanced (as in advanced settings) (used in FormatPanel)',
        defaultMessage: 'Advanced',
    },
    power: {
        id: 'general.power',
        description: 'Power (i.e. solar power)',
        defaultMessage: 'Power',
    },
    reset_to_default: {
        id: 'general.reset_to_default',
        description: 'Reset to default settings',
        defaultMessage: 'Reset to Default',
    },
    legend_position: {
        id: 'general.legend_position',
        description: 'The position of the map legend',
        defaultMessage: 'Legend Position',
    },
    top_left: {
        id: 'general.top_left',
        description: 'The top left position',
        defaultMessage: 'Top Left',
    },
    top_right: {
        id: 'general.top_right',
        description: 'The top right position',
        defaultMessage: 'Top Right',
    },
    bottom_left: {
        id: 'general.bottom_left',
        description: 'The bottom left position',
        defaultMessage: 'Bottom Left',
    },
    bottom_right: {
        id: 'general.bottom_right',
        description: 'The bottom right position',
        defaultMessage: 'Bottom Right',
    },
    data_source: {
        id: 'general.data_source',
        description: 'The data source for a series of data',
        defaultMessage: 'Data Source',
    },
    browse_team_files: {
        id: 'general.browse_team_files',
        description: "Browse files that have previously been uploaded for the user's team",
        defaultMessage: 'Browse Team Files',
    },
    search_files: {
        id: 'general.search_files',
        description: 'Placeholder text for search input -- browse files that have previously been uploaded',
        defaultMessage: 'Search files...',
    },
    position: {
        id: 'general.position',
        description: 'The visual position of an object (e.g. top, center)',
        defaultMessage: 'Position',
    },
    position_left: {
        id: 'general.position_left',
        description: 'Left position',
        defaultMessage: 'Left',
    },
    position_right: {
        id: 'general.position_right',
        description: 'Right position',
        defaultMessage: 'Right',
    },
    position_center: {
        id: 'general.position_center',
        description: 'Center position',
        defaultMessage: 'Center',
    },
    position_top: {
        id: 'general.position_top',
        description: 'Top position',
        defaultMessage: 'Top',
    },
    position_bottom: {
        id: 'general.position_bottom',
        description: 'Bottom position',
        defaultMessage: 'Bottom',
    },
    position_vertical: {
        id: 'general.position_vertical',
        description: 'Vertical position',
        defaultMessage: 'Vertical',
    },
    position_horizontal: {
        id: 'general.position_horizontal',
        description: 'Horizontal position',
        defaultMessage: 'Horizontal',
    },
});

export default GENERAL_STRINGS;
