/* eslint-disable id-length,consistent-return */

export function minimize(func, brack, tol = 1.48e-8, maxIter = 500, _cg = 0.3819660, _mintol = 1.0e-11) {
    const { xa, xb, xc, fb } = cleanBracket(func, brack);

    let x;
    let w;
    let v;
    let fx;
    let fw;
    let fv;
    let deltax;
    let a;
    let b;
    let tol1;
    let tol2;
    let xmid;
    let rat;
    let p;
    let dxTemp;
    let u;
    let fu;
    let tmp1;
    let tmp2;

    x = w = v = xb;
    fw = fv = fx = fb;

    if (xa < xc) {
        a = xa;
        b = xc;
    } else {
        a = xc;
        b = xa;
    }
    deltax = 0.0;

    for (let iter = 0; iter <= maxIter; iter++) {
        tol1 = tol * Math.abs(x) + _mintol;
        tol2 = 2.0 * tol1;
        xmid = 0.5 * (a + b);

        if (Math.abs(x - xmid) < (tol2 - 0.5 * (b - a))) {
            // # xmin = x  # unecssary are just reassigned
            // # fval = fx # unecssary are just reassigned
            break;
        }
        if (Math.abs(deltax) <= tol1) {
            deltax = (x >= xmid) ? (a - x) : b - x;
            // # if (x >= xmid): deltax = a - x       # do a golden section step
            // # else: deltax = b - x
            rat = _cg * deltax;
        } else {                              // do a parabolic step
            tmp1 = (x - w) * (fx - fv);
            tmp2 = (x - v) * (fx - fw);
            p = (x - v) * tmp2 - (x - w) * tmp1;
            tmp2 = 2.0 * (tmp2 - tmp1);

            if (tmp2 > 0.0) {
                p = -p;
            } else {
                tmp2 = -tmp2;
            }  // tmp2 = abs(tmp2)

            dxTemp = deltax;
            deltax = rat;
            // check parabolic fit
            if (p > tmp2 * (a - x) && p < tmp2 * (b - x) && Math.abs(p) < Math.abs(0.5 * tmp2 * dxTemp)) {
                rat = p * 1.0 / tmp2;        // if parabolic step is useful.
                u = x + rat;
                if ((u - a) < tol2 || (b - u) < tol2) {
                    rat = (xmid - x >= 0) ? tol1 : -tol1;
                }
            } else {
                deltax = (x >= xmid) ? a - x : b - x;
                rat = _cg * deltax;
            }
        }

        if (Math.abs(rat) < tol1) {  // update by at least tol1
            u = x + ((rat >= 0) ? tol1 : x - tol1);
        } else {
            u = x + rat;
        }

        fu = func(u);    // calculate new output value

        if (fu > fx) {   // if it's bigger than current
            if (u < x) {
                a = u;
            } else {
                b = u;
            }
            if ((fu <= fw) || (w === x)) {
                v = w;
                w = u;
                fv = fw;
                fw = fu;
            } else if ((fu <= fv) || (v === x) || (v === w)) {
                v = u;
                fv = fu;
            }
        } else {
            if (u >= x) {
                a = x;
            } else {
                b = x;
            }

            v = w;
            w = x;
            x = u;
            fv = fw;
            fw = fx;
            fx = fu;
            // v, w, x, fv, fw, fx = w, x, u, fw, fx, fu
        }
    }

    return x;
}

function cleanBracket(fn, brack) {
  // var res, xa, xb, xc, fa, fb, fc;
    if (brack === undefined) {
        return bracket(fn);
    } else if (brack.length === 2) {
        return bracket(fn, { xa: brack[0], xb: brack[1] });
    } else if (brack.length === 3) {
        let xa = brack[0];
        const xb = brack[1];
        let xc = brack[2];
        if (xa > xc) {
            [xa, xc] = [xc, xa];
        }

        if (!((xa <= xb) && (xb <= xc))) {
            throw new Error(`Not a bracketing interval (${xa}<?${xb}<?${xc})`);
        }

        const fa = fn(xa);
        const fb = fn(xb);
        const fc = fn(xc);

        if (!((fb < fa) && (fb < fc))) {
            throw new Error(`Not a bracketing interval (${fa}>?${fb}<?${fc})`);
        }

        return { xa, xb, xc, fa, fb, fc };
    }
}

const _gold = 1.618034;
const _verySmallNum = 1e-21;

function bracket(func, brack, gorwLimit = 110.0, maxIter = 1000) {
    let xa = brack.xa;
    let xb = brack.xb;

    let fa = func(xa);
    let fb = func(xb);

    let swap;
    let tmp1;
    let tmp2;
    let val;
    let denom;
    let w;
    let wlim;
    let fw;

    if (fa < fb) {
        swap = xa;
        xa = xb;
        xb = swap;

        swap = fa;
        fa = fb;
        fb = swap;
    }

    let xc = xb + _gold * (xb - xa);
    let fc = func(xc);

    let iter = 0;

    while (fc < fb) {
        tmp1 = (xb - xa) * (fb - fc);
        tmp2 = (xb - xc) * (fb - fa);
        val = tmp2 - tmp1;
        if (Math.abs(val) < _verySmallNum) {
            denom = 2.0 * _verySmallNum;
        } else {
            denom = 2.0 * val;
        }
        w = xb - ((xb - xc) * tmp2 - (xb - xa) * tmp1) / denom;
        wlim = xb + gorwLimit * (xc - xb);

        if (iter > maxIter) {
            throw new Error('Too many iterations.');
        }

        iter += 1;

        if ((w - xc) * (xb - w) > 0.0) {
            fw = func(w);

            if (fw < fc) {
                // xa, xb, fa, fb = xb, w, fb, fw
                return { xa: xb, xb: w, xc, fa: fb, fb: fw, fc };
            } else if (fw > fb) {
                // xc, fc = w, fw
                return { xa, xb, xc: w, fa, fb, fc: fw };
            }
            w = xc + _gold * (xc - xb);
            fw = func(w);
        } else if ((w - wlim) * (wlim - xc) >= 0.0) {
            w = wlim;
            fw = func(w);
        } else if ((w - wlim) * (xc - w) > 0.0) {
            fw = func(w);

            if (fw < fc) {
                xb = xc;
                xc = w;
                fb = fc;
                fc = fw;

                w = xc + _gold * (xc - xb);
                fw = func(w);
            }
        } else {
            w = xc + _gold * (xc - xb);
            fw = func(w);
        }
        xa = xb;
        xb = xc;
        xc = w;
        fa = fb;
        fb = fc;
        fc = fw;
      // xa, xb, xc, fa, fb, fc = xb, xc, w, fb, fc, fw
    }
    return { xa, xb, xc, fa, fb, fc };
}
