/* tslint:disable:variable-name function-name */
import { schema } from './schema';
import { BaseClass } from 'reports/utils/api';
import type { Subscription } from 'reports/models/subscription';
import type { User } from 'reports/models/user';

class SubscriptionUser extends BaseClass {
    external_id: number;
    subscription: Subscription;
    user_id: number;
    user: User;
}

const schemaObj = schema.addObject(SubscriptionUser, 'subscription_user', {
    compositeKeyFn: (obj) => `${obj.external_id},${obj.user_id}`,
});

export { SubscriptionUser, schemaObj };
