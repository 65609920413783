import Logger from 'js-logger';
import {
    assignWith,
    chain,
    filter,
    get,
    includes,
    isMatchWith,
    isPlainObject,
    map,
    omit,
    toPairs,
    uniq,
    uniqueId,
    unset,
} from 'lodash';

import actionCreatorFactory, { isType, Action } from 'typescript-fsa';

import { ArrayElement, DeepPartial, KeysNotOfType, KeysOfType } from 'reports/utils/types';

const logger = Logger.get('schema');
const actionCreator = actionCreatorFactory('schema');

export const dataLoaded = actionCreator<{ schema: SchemaObject; rawData: any }>('RAW_DATA_LOADED');
export const entityDeleted = actionCreator<{
    schema: SchemaObject;
    rawData: any;
    cascade?: boolean;
}>('ENTITY_DELETED');
export const patchCreated = actionCreator<IPatch>('PATCH_CREATED');
export const patchesRemoved = actionCreator<IPatches>('PATCH_REMOVED');

export const getPatchId = () => uniqueId('stageChange');

// keys on an object that can be used as IDs
type IdProp<I> = keyof KeysOfType<I, number | string | undefined>;

// keys on an object that can be used as a backref
type PathProp<Parent, Relation> = keyof KeysOfType<Parent, Relation | undefined>;

// keys on an object that can be used as a backref
type BackrefProp<Parent, Relation> = keyof KeysOfType<Relation, Parent[] | undefined>;

// standard js object functions can confuse the typing, and they wouldn't be mapped
// to relationships anyway
type MappableProps<I> = KeysNotOfType<I, Function>;

interface IRelConfigNoBackref<Parent> {
    idName?: IdProp<Parent>; // key linking to the child
}

interface IRelConfigBackref<Parent, Relation> {
    backref: BackrefProp<Parent, Relation>;
    idName?: IdProp<Parent>;
}

interface IRelationshipConfig<Parent, Relation> {
    schema: SchemaObject<Exclude<Relation, undefined>>;
    backref?: BackrefProp<Parent, Exclude<Relation, undefined>>;
    idName?: IdProp<Parent>;
}

interface IRelationship<I, Rel> {
    normalizer: IRelationNormalizer;
    selector: ISelector<Rel, I>; // (appState: any, obj: I, deep?: boolean) => I[K],
    isArray: boolean;
    idName: string; // the final configured ID that points to the relationship
    config: IRelationshipConfig<I, Rel>;
}

interface ISchemaConfig<I, CleanI = MappableProps<I>> {
    relationships?: { [K in keyof CleanI]?: IRelationshipConfig<I, CleanI[K]> };
    idName?: IdProp<I>;
    compositeKeyFn?: (obj: Partial<CleanI>) => string;

    defaultDeep?: IDeepSelect<I>; // TODO: delete and fixup object definition (deprecated)
}

interface IRelationNormalizer {
    (childData: any, parentData: any, state: any): void;
}

interface ISelectAllOpts<I> {
    filter?: (obj: I) => boolean;
    deep?: boolean | IDeepSelect<I>; // TODO: delete IDeepSelect and fixup object definition (deprecated)
}

export interface IPatchMeta<I = any> {
    schema: SchemaObject<I>;
    objectId: string | number;
}

export interface IPatch<I = any> extends IPatchMeta<I> {
    patchId: string;
    delta: DeepPartial<I>;
}

export interface IPatches<I = any> extends IPatchMeta<I> {
    patches?: IPatch<I>[];
}

export type IDeepSelect<I, CleanI = KeysNotOfType<I, Function>> =
    | (keyof CleanI)[] // array of relations to load
    | '*' // load every relation
    | Partial<{
          [K in keyof CleanI]:
              | boolean // load the relationship at this Key
              | IDeepSelect<
                    // follow this configuration for the relationship
                    CleanI[K] extends any[] ? ArrayElement<CleanI[K]> : CleanI[K]
                >;
      }>;

type IDeepSelectOpts<I> = IDeepSelect<I> | undefined;

export interface ISelector<Relation, Parent = any, IAppState = any> {
    (state: IAppState, rawData: Parent | object, deep?: boolean | IDeepSelect<Relation>): Relation | undefined | null;
}

export interface IArraySelector<Relation, Parent = any, IAppState = any> {
    (state: IAppState, rawData: Parent | object, deep?: boolean): Relation[];
}

function _patchMergeFn(objVal, srcVal) {
    if (srcVal !== objVal && isPlainObject(srcVal) && isPlainObject(objVal)) {
        return patchMerge(objVal, [srcVal]);
    }

    return undefined;
}

export function patchMerge(base, patches) {
    return assignWith<any>({ ...base }, ...patches, _patchMergeFn);
}

const HAS_RELATIONSHIPS = Symbol('HAS_RELATIONSHIPS');

/**
 * create a schema for normalizing API data to store in a redux store that
 * assumes immutability, with selectors for resaturating objects with their
 * relationships
 */
export class SchemaObject<I = any, AppState = any> {
    schema: Schema;
    cls: { new (...args): I };
    name: string; // name for the entity
    config: ISchemaConfig<I>;

    isAssociationTable: boolean;
    idName?: IdProp<I>; // the local ID Name

    relationships: { [K in keyof I]?: IRelationship<I, I[K]> } = {};

    dependencies: { schema: SchemaObject; referenceIdName: string | number }[] = [];

    deserializedCache: WeakMap<any, I> = new WeakMap();

    constructor(manager, cls: { new (...args): I }, name: string, config: ISchemaConfig<I> = {}) {
        this.schema = manager;
        this.cls = cls;
        this.name = name;
        this.config = config;

        if (config.compositeKeyFn) {
            logger.info(`Configuring ${name} as association`);
            this.isAssociationTable = true;
        } else {
            this.isAssociationTable = false;
            this.idName = config.idName ? config.idName : (`${name}_id` as any);
        }

        const { relationships } = config;
        this.loadRelationships(relationships as any);
    }

    addRelationship<IRel>(path: PathProp<I, IRel>, foreignSchema: SchemaObject<IRel>): { selector: ISelector<IRel, I> }; // Typing for auto configured relationships w/o backrefs

    addRelationship<IRel>( // typing for manually configured relationships w/o backrefs
        path: PathProp<I, IRel>,
        foreignSchema: SchemaObject<IRel>,
        config: IRelConfigNoBackref<I>,
    ): {
        selector: ISelector<IRel, I>;
    };

    addRelationship<IRel>( // typing for relationships confiured w/ a backref
        path: PathProp<I, IRel>,
        foreignSchema: SchemaObject<IRel>,
        config: IRelConfigBackref<I, IRel>, // | IRelationship<I, IRel>,
    ): {
        selector: ISelector<IRel, I>;
        backrefSelector: IArraySelector<I, IRel>;
    };

    /**
     * Define a relationship between this object and a related schema
     * @param path the location of the mapped object
     * @param foreignSchema schema object defining the object to be mapped
     * @param config relationship configuration
     */
    addRelationship<IRel>(path: PathProp<I, IRel>, foreignSchema: SchemaObject<IRel>, config?: any) {
        const pathName: string = String(path);
        if (foreignSchema === undefined) {
            throw Error(`Undefined target for ${this.name}.${pathName}. Class may not be available yet`);
        } else if (foreignSchema.isAssociationTable || foreignSchema.idName == null) {
            // don't need the idName check (guaranteed to be true), but it lets the typechecker know
            // idName will defined below
            throw Error(
                `Cannot define relationship from path (${this.name}.${pathName}) to association table ` +
                    `${foreignSchema.name} Relationships to tables with composite keys must be defined` +
                    'from that table',
            );
        }

        const idName = config != null && config.idName != null ? config.idName : `${pathName}_id`;
        foreignSchema.dependencies.push({
            schema: this,
            referenceIdName: idName,
        });
        const normalizer = SchemaObject.createRelationNormalizer<IRel, I>(foreignSchema, foreignSchema.idName, idName);

        // Select an object from the relation's schema using the key on the local object
        const selector: ISelector<IRel, I> = (state, obj, deep?) => foreignSchema.selectById(state, obj[idName], deep);

        this.relationships[path] = {
            idName,
            normalizer,
            selector,
            isArray: false,
            config: { ...config, schema: foreignSchema },
        } as any;

        if (config != null && config.backref != null) {
            const { backref } = config;

            const relNormalizer = SchemaObject.createRelationNormalizer(this, idName, foreignSchema.idName);

            const arrayNormalizer = (relationDataArr, rawData, initialState) => {
                let state = initialState;

                for (const relationData of relationDataArr) {
                    state = relNormalizer(relationData, rawData, state);
                }
                return state;
            };

            const arraySelector: IArraySelector<I, IRel> = (state: AppState, parent: IRel, deep?: boolean): I[] =>
                this.selectAll(state, {
                    deep,
                    filter: (child: I) => foreignSchema.objectId(parent) === child[idName],
                });

            foreignSchema.relationships[backref] = {
                normalizer: arrayNormalizer,
                selector: arraySelector,
                isArray: true,
                config: { schema: this },
            };

            return { selector, backrefSelector: arraySelector };
        }

        return { selector };
    }

    loadRelationships(relationConfig: { [K in keyof I]?: IRelationshipConfig<I, I[K]> } = {}) {
        for (const [propertyPath, relationship] of toPairs(relationConfig) as any) {
            const { schema } = relationship;
            this.addRelationship(propertyPath, schema, relationship);
        }
    }

    /**
     * ensure consistent keys between parent and child in case linking IDs are only present on some of the data
     *
     * @param {SchemaObject<IRel>} relationConfig the schema configuration for the related entity that should be
     *                                            denormalized and parsed off the raw parent data
     * @param {IdProp<IRel>} fkToPrimaryObj  the ID Name on the relation data that binds it the primary/parent data
     * @param {IdProp<I>} fkToRelatedObj the ID Name on the primary/parent data that binds it to the relation
     */
    static createRelationNormalizer<IRel, I>(
        relationConfig: SchemaObject<IRel>,
        fkToPrimaryObj: IdProp<IRel>,
        fkToRelatedObj: IdProp<I>,
    ) {
        return (relationData, primaryData, state) => {
            const primaryObjId = primaryData[fkToRelatedObj];
            const relatedObjId = relationData[fkToPrimaryObj];

            if (relatedObjId && primaryObjId && relatedObjId !== primaryObjId) {
                throw Error(
                    `Inconsistent Object IDs on ${relationConfig.name}:` +
                        `${String(fkToRelatedObj)}(${primaryObjId}) != ` +
                        `${relationConfig.name}.${String(relationConfig.idName)}(${relatedObjId})`,
                );
            } else if (relatedObjId && !primaryObjId) {
                primaryData[fkToRelatedObj] = relatedObjId;
            } else {
                relationData[fkToPrimaryObj] = primaryObjId;
            }

            return relationConfig.normalize(relationData, state);
        };
    }

    normalize(rawData: any, initialState: any) {
        // if an object changes, return a new stateBranch entity that copies the original + the changes
        const dataCopy = { ...rawData };
        let state = initialState;

        for (const [path, { normalizer, idName }] of toPairs(this.relationships) as any) {
            const childData = get(dataCopy, path, undefined);
            if (childData === null) {
                // if the object contains a null relation, ensure that the id for the relation is also null
                const childDataId = get(dataCopy, idName, null);
                if (childDataId !== null) {
                    throw Error(
                        `Inconsistent data on ${dataCopy.name}: ${path} is null when ${idName} is not null (${childDataId})`,
                    );
                }
                dataCopy[idName] = null;
            } else if (childData !== undefined) {
                state = normalizer(childData, dataCopy, state);
            }

            unset(dataCopy, path);
        }

        const id = this.objectId(dataCopy);
        if (!id) {
            throw Error(`No primary key for normalization ${this.name}.${String(this.idName)} cannot add to store`);
        }

        const stateBranch = state.data[this.name];
        const preexisting = stateBranch[id];

        // The isMatchWith check prevents us from purging the cached relationships when the new data being loaded
        // is identical to the data in the state. We want to avoid doing expensive deep equality checks, so we
        // only do a shallow equality check on non-relational objects/arrays
        if (isMatchWith(preexisting, dataCopy, (objVal, srcVal) => objVal === srcVal)) {
            return state;
        }

        const newObj = { ...preexisting, ...dataCopy };

        this.purgeCachedRelations(newObj, initialState);

        return {
            patches: state.patches,
            data: {
                ...state.data,
                [this.name]: {
                    ...stateBranch,
                    [id]: newObj,
                },
            },
        };
    }

    delete(rawData: any, initialState: any, cascade: boolean = false) {
        // if an object changes, return a new stateBranch entity that copies the original + the changes
        let state = initialState;
        const stateBranch = state.data[this.name];
        const patchBranch = state.patches[this.name];

        const id = this.objectId(rawData);
        const preexisting = stateBranch[id];

        if (!preexisting) {
            logger.warn('Tried to delete an object that does not exist on the state', rawData);
            return state;
        }

        state = {
            data: {
                ...state.data,
                [this.name]: omit(stateBranch, [id]),
            },
            patches: {
                // should probably warn if there are outstanding patches
                ...state.patches,
                [this.name]: omit(patchBranch, [id]),
            },
        };

        for (const { schema, referenceIdName } of this.dependencies) {
            const matches = schema.selectRaw(state, (obj) => obj[referenceIdName] === id);
            for (const match of matches) {
                if (cascade) {
                    state = schema.delete(match, state, cascade);
                } else {
                    // TODO: this should do something to check for patches
                    const matchedId = schema.objectId(match);
                    const matchedstateData = state.data[schema.name];

                    // this is already entirely a fresh copy of the state, so need to make another
                    state.data[schema.name] = {
                        ...matchedstateData,
                        [matchedId]: {
                            ...match,
                            [referenceIdName]: undefined,
                        },
                    };
                }
            }
        }

        return state;
    }

    addPatch(state: any, objectId: string | number, patchId: string, delta: DeepPartial<I>) {
        const patch = {
            ...(delta as any),
            __patchId: patchId, // note: no protection for duplicate patches
        };

        const currPatches = state.patches[this.name][objectId];

        return {
            data: state.data,
            patches: {
                ...state.patches,
                [this.name]: {
                    ...state.patches[this.name],
                    [objectId]: (currPatches != null ? currPatches : []).concat(patch),
                },
            },
        };
    }

    removePatches(state: any, objectId: string | number, patches?: IPatch[]) {
        let updatedPatches;

        if (patches !== undefined) {
            const patchSet = new Set<string>(map(patches, 'patchId'));
            const objectPatches: (DeepPartial<I> & { __patchId: string })[] = state.patches[this.name][objectId];

            updatedPatches = filter(objectPatches, (x) => !patchSet.has(x.__patchId));
        } else {
            // Clear all patches for object
            updatedPatches = [];
        }

        if (patches != null && patches.length === updatedPatches.length) {
            logger.warn(`Could not remove patches on ${this.name}(${objectId})`);
        }

        return {
            data: state.data,
            patches: {
                ...state.patches,
                [this.name]: {
                    ...state.patches[this.name],
                    [objectId]: updatedPatches,
                },
            },
        };
    }

    getInitialState() {
        const rtn = {
            patches: {},
            data: {},
        };

        for (const rel of this.allRelationships()) {
            rtn.patches[rel.name] = {};
            rtn.data[rel.name] = {};
        }

        return rtn;
    }

    allRelationships(relatedSchemas = new Set<SchemaObject>()) {
        const allDependencies = uniq([
            ...Object.values(this.relationships).map((x: any) => x.config.schema),
            ...this.dependencies.map((x) => x.schema),
        ]);
        for (const schema of allDependencies) {
            if (!relatedSchemas.has(schema)) {
                relatedSchemas.add(schema);
                for (const subSchema of schema.allRelationships(relatedSchemas)) {
                    relatedSchemas.add(subSchema);
                }
            }
        }

        return relatedSchemas;
    }

    /**
     * Add relationships to an instance upon selecting it from the state.
     * this runs in a two passes prevent cycles:
     *     - pass one:
     *          - add a reference for every direct relation to the entity
     *             - note: for any given version of the redux state, every entity should have
     *                     exactly one reference to a saturated object (populated from the raw data)
     *         - mark this entity as having all it's relationships populated, this entity will never
     *           traverse it's relationships again (if the state is updated, a new object
     *           will be created and populated when selected)
     *     - pass two: for all direct relationships of the entity that have not been marked as having their
     *       relationships loaded, populate their relationships using the same approach.  Because the parent
     *       entity is marked that it's been visited, it won't be traversed again, preventing cycles.
     *
     * @param {I} instance
     * @param appState application state
     */
    private populateRelationships(instance: I, appState: any) {
        const needsDeep: { relation: any; schema: SchemaObject<any> }[] = [];

        for (const key of Object.keys(this.relationships)) {
            const relationship: IRelationship<any, any> = this.relationships[key];
            const relation = relationship.selector(appState, instance, false);
            instance[key] = relation;

            if (relation == null) {
                continue;
            }

            if (relationship.isArray) {
                for (const rel of relation) {
                    if (!rel[HAS_RELATIONSHIPS]) {
                        needsDeep.push({
                            relation: rel,
                            schema: relationship.config.schema,
                        });
                    }
                }
            } else if (!relation[HAS_RELATIONSHIPS]) {
                needsDeep.push({
                    relation,
                    schema: relationship.config.schema,
                });
            }
        }
        instance[HAS_RELATIONSHIPS] = true;

        for (const { relation, schema } of needsDeep) {
            schema.populateRelationships(relation, appState);
        }
    }

    /**
     * purge the cache for any objects that reference a given object.  Even if their data didn't change, if they
     * have relationships (ie references) to this object, then their references should also be updated to preserve
     * immutability whenever an object changes. Related objects pointing back to this instance can from two sources:
     *   1. any objects that have a relationship to this object (dependencies)
     *   2. any relationships configured on this object, with a backref pointing back to it
     *      if this object is `child` and has a relationship `child.parent` and `parent.children` is the configured
     *      backref, then parent must be purged
     * @param {Partial<I>} rawObjData any raw data including this object's id
     * @param schemaState the current state of the schema
     */
    private purgeCachedRelations(rawObjData: Partial<I>, schemaState: any) {
        const objId = this.objectId(rawObjData);

        for (const { schema, referenceIdName } of this.dependencies) {
            const relations = schema.selectRaw(schemaState, (relation) => relation[referenceIdName] === objId);
            for (const relation of relations) {
                // only purge deeply if an item removed from cache (prevents infinite recursion)
                if (schema.deserializedCache.delete(relation)) {
                    schema.purgeCachedRelations(relation, schemaState);
                }
            }
        }

        const relationships: IRelationship<any, any>[] = Object.values(this.relationships);

        for (const { config, idName } of relationships) {
            if (config.backref) {
                const { schema } = config;
                const relation = schema.selectRawById(schemaState, rawObjData[idName]);
                // only purge deeply if an item removed from cache (prevents infinite recursion)
                if (schema.deserializedCache.delete(relation)) {
                    schema.purgeCachedRelations(relation, schemaState);
                }
            }
        }
    }

    objectId(obj: I | any) {
        if (this.config.compositeKeyFn) {
            return this.config.compositeKeyFn(obj);
        }
        // idName will be defined if there's no composite key
        return obj[this.idName!];
    }

    objectKey(obj: I | any) {
        return `${this.name}.${this.objectId(obj)}`;
    }

    selectRaw(schemaState: any, filter?: (rawObj: any) => boolean): any[] {
        const stateData = schemaState.data[this.name];
        const statePatches = schemaState.patches[this.name];

        const rtn = chain(stateData).map((rawVal, id) => {
            const patches = statePatches[id];
            if (patches == null || patches.length === 0) {
                return rawVal;
            }

            // this will put patch IDs on the object, potentially non-ideal
            return patchMerge(rawVal, patches);
        });

        if (filter != null) {
            return rtn.filter(filter).value();
        }

        return rtn.value();
    }

    /**
     * select the raw POJO + optionally related patches for a given object
     * should return the raw object if it exists, null if there is no id (because
     * there shold not be an object) or undefined if there should be an object,
     * but it is not in the state
     */
    selectRawById(schemaState: any, id: number | string | null | undefined, withPatches = true): any {
        if (id == null) {
            return null; // there is no ID so there should be no object
        }

        const rawVal = schemaState.data[this.name][id];

        if (!withPatches) {
            return rawVal;
        }

        const patches = schemaState.patches[this.name][id];

        if (patches == null || patches.length === 0) {
            return rawVal;
        }

        // this will put patch IDs on the object, potentially non-ideal
        return patchMerge(rawVal, patches);
    }

    /**
     * select pending patches for a given object
     * should return the merged patch object if patches exist, null if there is no id or id not found
     */
    selectPatchesById(appState: AppState, id: number | string): any {
        const schemaState = this.schema.schemaState(appState);
        const patches = schemaState.patches[this.name][id];

        if (!patches || patches.length === 0) {
            return null;
        }

        return patchMerge({}, patches);
    }

    deserialize(rawData: any, appState: any, addRelationships: boolean = true): I {
        // this should be the only place where we have 'internal' app state, because it's really just a cache for
        // selectors (e.g. like reselect but gneric)

        let deserialized = this.deserializedCache.get(rawData);

        if (deserialized == null) {
            deserialized = new this.cls({ ...rawData });
            deserialized[HAS_RELATIONSHIPS] = false;
            this.deserializedCache.set(rawData, deserialized);
        }

        if (addRelationships && !deserialized[HAS_RELATIONSHIPS]) {
            this.populateRelationships(deserialized, appState);
        }

        return deserialized;
    }

    // these are bound by default so that they can be used elsewhere without a
    // reference to the schema object itself
    // NOTE: these also all operate off the full application state vs just the
    // branch for the schema (used elsewhere)
    ////////////////////////////////////////////////////////////////////////////

    /**
     * prune properties attached to an object via the schema/relationships off the object
     * @param {Partial<I>} obj object to be pruned
     * @param {(keyof I)[]} includeRelationships array of properties not to be removed
     */
    pruneRelationships = (obj: DeepPartial<I>, includeRelationships: (keyof I)[] = []) => {
        const copy = Object.assign({}, obj);

        for (const [relId, rel] of Object.entries(this.relationships) as any) {
            if (includes(includeRelationships, relId) && copy[relId] != null) {
                const relPrune = rel.config.schema.pruneRelationships;
                copy[relId] = rel.isArray ? copy[relId].map(relPrune) : relPrune(copy[relId]);
            } else {
                delete copy[relId];
            }
        }

        return copy;
    };

    /**
     * parse and normalize raw data for this object into the schema store
     * @param {Partial<I>} rawData
     * @returns {Action} action to dispatch to load and normalize the data in redux
     */
    dataLoaded = (rawData: Partial<I>) => {
        return dataLoaded({ rawData, schema: this });
    };

    /**
     * remove an object from the schema
     * @param {Partial<I>} rawData
     * @returns {Action} action to dispatch to remove the object from the redux store
     */
    entityDeleted = (rawData: Partial<I>) => {
        return entityDeleted({ rawData, schema: this });
    };

    /**
     * Select an object by ID
     * @param {AppState} the application state
     * @param {boolean} [deepSelect=true] load all relationships
     *                   Note: support deep select options to reduce code churn, but these will now all
     *                         load all relationships
     * @returns {I | undefined | null} saturated object from the state
     */
    selectById = (
        appState: AppState,
        id: number | string | undefined,
        deep: boolean | IDeepSelect<I> = true,
    ): I | undefined | null => {
        if (this.isAssociationTable) {
            throw new Error(`Cannot select by id for association object ${this.name}`);
        }

        const raw = this.selectRawById(this.schema.schemaState(appState), id);
        if (raw != null) {
            return this.deserialize(raw, appState, Boolean(deep));
        }
        return raw; // matters if it is undefined or null
    };

    /**
     * Select an object by an object (that has an object Id).
     * @param {AppState} the application state
     * @param {Partial<I>} a partial version of the object
     * @param {boolean} [deepSelect=true] load all relationships
     *                   Note: support deep select options to reduce code churn, but these will now all
     *                         load all relationships
     * @returns {I | undefined | null} saturated object from the state
     */
    selectByObject = (
        appState: AppState,
        obj: Partial<I>,
        deep: boolean | IDeepSelectOpts<I> = true,
    ): I | undefined | null => {
        if (obj == null) {
            return null;
        }

        const raw = this.selectRawById(this.schema.schemaState(appState), this.objectId(obj));
        if (raw != null) {
            return this.deserialize(raw, appState, Boolean(deep));
        }
        return raw; // matters if it is undefined or null
    };

    /**
     * Select all objects and related patches from a schema.
     * @param {AppState} the application state
     * @param {ISelectAllOpts<I>} [options] for selecting each object
     */
    selectAll = (appState: AppState, { filter = undefined, deep = true }: ISelectAllOpts<I> = {}): I[] => {
        return this.selectRaw(this.schema.schemaState(appState), filter).map((x) => {
            return this.deserialize(x, appState, Boolean(deep));
        });
    };
}

export class Schema<AppState = any> {
    stateKey: string = '';
    schemas: SchemaObject[] = [];

    /**
     * add a new object type to be parsed by the schema
     * @param {{ new(...args): I }} cls - the class constructor for saturating the object
     * @param {string} name - a name of the object for use in the store (typically the tablename)
     * @param {ISchemConfig<I>} config
     * @returns {SchemaObject<I>}
     */
    addObject<I>(cls: { new (...args): I }, name: string, config: ISchemaConfig<I> = {}) {
        const schema = new SchemaObject<I, AppState>(this, cls, name, config);
        this.schemas.push(schema);

        return schema;
    }

    schemaState(state: AppState) {
        return state[this.stateKey];
    }

    getReducer(stateKey: string) {
        this.stateKey = stateKey;

        let allRelationships = new Set<SchemaObject>(this.schemas);

        for (const schema of this.schemas) {
            allRelationships = schema.allRelationships(allRelationships);
        }

        const getInitialState = () => {
            const initialState = { data: {}, patches: {} };

            for (const schema of allRelationships) {
                initialState['data'][schema.name] = {};
                initialState['patches'][schema.name] = {};
            }

            return initialState;
        };

        return (state: any = getInitialState(), action: Action<any>) => {
            if (isType(action, dataLoaded)) {
                const updatedState = action.payload.schema.normalize(action.payload.rawData, state);
                return { ...updatedState };
            }
            if (isType(action, patchCreated)) {
                const { objectId, delta, patchId } = action.payload;
                return action.payload.schema.addPatch(state, objectId, patchId, delta);
            }
            if (isType(action, patchesRemoved)) {
                return action.payload.schema.removePatches(state, action.payload.objectId, action.payload.patches);
            }
            if (isType(action, entityDeleted)) {
                const updatedState = action.payload.schema.delete(
                    action.payload.rawData,
                    state,
                    action.payload.cascade,
                );

                return { ...updatedState };
            }
            {
                return state;
            }
        };
    }
}

export default SchemaObject;
