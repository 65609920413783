/**
 * Defines the mathematical/physical model for how a module works. Matches the computations done by the simulators.
 * (These classes were ported over from the angular classes of the same name)
 */
/* tslint:disable:variable-name */
import { minimize, rootFind } from 'helioscope/app/utilities/optimization';
import type { ModuleCharacterization } from 'reports/models/module';

abstract class DiodeModule {
    characterization: ModuleCharacterization;
    irradiance: number;
    temp: number;

    constructor(characterization, irradiance, temp) {
        this.characterization = characterization;
        this.irradiance = irradiance;
        this.temp = temp;
    }

    setConditions = (irradiance, temp) => {
        this.irradiance = parseFloat(irradiance);
        this.temp = parseFloat(temp);
    };

    iSc = () => {
        const vDiode = rootFind((x) => this.voltage(x), -5, this.characterization.v_oc, 1e-5);

        return this.current(vDiode);
    };

    vOc = () => {
        const vDiode = rootFind((x) => this.current(x), 0, this.characterization.v_oc * 2, 1e-5);

        return this.voltage(vDiode);
    };

    maxPower = (bounds) => {
        const newBounds = bounds || [-20, 2 * this.characterization.v_oc];

        const vDiode = minimize((x) => {
            const i = this.current(x);
            return -this.voltage(x, i) * i;
        }, newBounds);
        const cur = this.current(vDiode);
        const vol = this.voltage(vDiode, cur);

        return {
            vDiode,
            vMp: vol,
            iMp: cur,
        };
    };

    abstract current: (vDiode: number) => number;
    abstract voltage: (vDiode: number, current?: number) => number;
}

export class FullDiodeModule extends DiodeModule {
    temp_adj_saturation_current = () => {
        return this.characterization.i0 * Math.pow(1 + this.characterization.temp_i0, this.temp - 25);
    };

    diode_coefficient = () => {
        return ModulePhysics.Q_K / absolute_temp(this.temp) / this.characterization.a;
    };

    short_circuit_current = () => {
        return (this.irradiance / 1000) * this.characterization.i_sc;
    };

    current = (vDiode) => {
        return (
            this.short_circuit_current() -
            this.temp_adj_saturation_current() * (Math.exp(vDiode * this.diode_coefficient()) - 1) -
            vDiode / this.characterization.parallel_resistance
        );
    };

    voltage = (vDiode: number, current?: number) => {
        return vDiode - this.characterization.series_resistance * (current || this.current(vDiode));
    };
}

export class PVSystDiodeModule extends DiodeModule {
    gamma = () => {
        return (
            this.characterization.gamma_ref + this.characterization.mu_gamma * (this.temp - this.characterization.t_ref)
        );
    };

    saturation_current = () => {
        return (
            this.characterization.i0_ref *
            Math.pow(absolute_temp(this.temp) / absolute_temp(this.characterization.t_ref), 3) *
            Math.exp(
                ((ModulePhysics.Q * this.characterization.module.cell_technology.adj_bandgap) /
                    (ModulePhysics.K * this.gamma())) *
                    (1 / absolute_temp(this.characterization.t_ref) - 1 / absolute_temp(this.temp)),
            )
        );
    };

    photocurrent = () => {
        return (
            (this.irradiance / this.characterization.g_ref) *
            (this.characterization.iph_ref +
                (this.characterization.mu_isc / 1000) *
                    (absolute_temp(this.temp) - absolute_temp(this.characterization.t_ref)))
        );
    };

    shunt_resistance = () => {
        return (
            this.characterization.rshunt_gref +
            (this.characterization.rshunt_0 - this.characterization.rshunt_gref) *
                Math.exp((-this.characterization.rshunt_exp * this.irradiance) / this.characterization.g_ref)
        );
    };

    exponent_coefficient = () => {
        return (
            ModulePhysics.Q /
            (ModulePhysics.K * absolute_temp(this.temp) * this.characterization.module.series_cells * this.gamma())
        );
    };

    recombination_coefficient = () => {
        return Math.max(0, this.photocurrent() * this.characterization.di2_mu_tau);
    };

    voltage = (vDiode, current) => {
        const cur = current || this.current(vDiode);
        return vDiode - cur * this.characterization.rseries;
    };

    current = (vDiode) => {
        return (
            this.photocurrent() -
            this.saturation_current() * (Math.exp(this.exponent_coefficient() * vDiode) - 1) -
            vDiode / this.shunt_resistance() -
            this.recombination_coefficient() /
                (this.characterization.module.cell_technology.vbi * this.characterization.module.series_cells - vDiode)
        );
    };
}

export enum ModulePhysics {
    Q = 1.60217657e-19,
    K = 1.3806488e-23,
    T = 273,
    Q_K = Q / K,
}

const absolute_temp = (celsius: number) => ModulePhysics.T + celsius;
