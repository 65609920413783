/**
 * Predeclared messages file for react-intl
 *
 * The `defineMessages` call is so that all messages can be parsed out via AST for translation.
 */

import { defineMessages } from 'react-intl';

const FINANCIAL_STRINGS = defineMessages({
    cash_flow: {
        id: 'financial.cash_flow',
        description: 'Cash Flow',
        defaultMessage: 'Cash Flow',
    },
    cumulative_cash_flow: {
        id: 'financial.cumulative_cash_flow',
        description: 'Cumulative Cash Flow',
        defaultMessage: 'Cumulative Cash Flow',
    },
    lcoe: {
        id: 'financial.lcoe',
        description: 'Levelized Cost of Energy',
        defaultMessage: 'Levelized Cost of Energy',
    },
    system_npv_cash: {
        id: 'financial.system_npv_cash',
        description: 'Lifetime Value (NPV)',
        defaultMessage: 'Lifetime Value (NPV)',
    },
    payback_period: {
        id: 'financial.payback_period',
        description: 'Payback Period',
        defaultMessage: 'Payback Period',
    },
    financial_summary: {
        id: 'financial.financial_summary',
        description: 'Financial Summary',
        defaultMessage: 'Financial Summary',
    },
    consumption: {
        id: 'financial.consumption',
        description: 'Consumption (as in Energy Consumption)',
        defaultMessage: 'Consumption',
    },
    production: {
        id: 'financial.production',
        description: 'Production (as in Energy Production)',
        defaultMessage: 'Production',
    },
    with_solar: {
        id: 'financial.with_solar',
        description: 'With Solar',
        defaultMessage: 'With Solar',
    },
    without_solar: {
        id: 'financial.without_solar',
        description: 'Without Solar',
        defaultMessage: 'Without Solar',
    },
    system_start: {
        id: 'financial.system_start',
        description: 'System Start',
        defaultMessage: 'System Start',
    },
    total_value_of_energy: {
        id: 'financial.total_value_of_energy',
        description: 'Total Value of Energy',
        defaultMessage: 'Total Value of Energy',
    },
    internal_rate_of_return: {
        id: 'financial.internal_rate_of_return',
        description: 'Internal Rate of Return (IRR)',
        defaultMessage: 'Internal Rate of Return (IRR)',
    },
    return_on_investment: {
        id: 'financial.return_on_investment',
        description: 'Return on Investment (ROI)',
        defaultMessage: 'Return on Investment (ROI)',
    },
    lifetime_production_formatter: {
        id: 'financial.lifetime_production_formatter',
        description: 'Lifetime Production Formatter',
        defaultMessage: 'Year {x}: {y}',
    },
    utility_bill: {
        id: 'financial.utility_bill',
        description: 'Utility Bill',
        defaultMessage: 'Utility Bill',
    },
    utility: {
        id: 'financial.utility',
        description: 'Utility (as in Utility Bill)',
        defaultMessage: 'Utility',
    },
    solar: {
        id: 'financial.solar',
        description: 'Solar (as in Utility Bill)',
        defaultMessage: 'Solar',
    },
});

export default FINANCIAL_STRINGS;
