/**
 * Predeclared messages file for react-intl
 *
 * The `defineMessages` call is so that all messages can be parsed out via AST for translation.
 */

import { defineMessages } from 'react-intl';

const CONDITIONS_STRINGS = defineMessages({
    weather_source: {
        id: 'conditions.weather_source',
        description: 'Weather data source (for solar)',
        defaultMessage: 'Weather Source',
    },
    temperature_model: {
        id: 'conditions.temperature_model',
        description: 'Temperature Model (for solar)',
        defaultMessage: 'Temperature Model',
    },
    transposition_model: {
        id: 'conditions.transposition_model',
        description: 'Transposition Model (for solar)',
        defaultMessage: 'Transposition Model',
    },
    perez_model: {
        id: 'conditions.perez_model',
        description: 'Perez Model (weather model for solar)',
        defaultMessage: 'Perez Model',
    },
    hay_model: {
        id: 'conditions.hay_model',
        description: 'Hay Model (weather model for solar)',
        defaultMessage: 'Hay Model',
    },
    weather_dataset: {
        id: 'conditions.weather_dataset',
        description: 'Weather Dataset',
        defaultMessage: 'Weather Dataset',
    },
    near_shade_profile: {
        id: 'conditions.near_shade_profile',
        description: 'Near Shade Profile',
        defaultMessage: 'Near Shade Profile',
    },
    weather_dataset_tooltip: {
        id: 'conditions.weather_dataset_tooltip',
        description: 'Weather Dataset tooltip content',
        defaultMessage: 'Weather data used to generate energy production estimates.',
    },
    temperature_delta: {
        id: 'conditions.temperature_delta',
        description: 'Temperature Delta',
        defaultMessage: 'Temperature Delta',
    },
    solar_angle_location: {
        id: 'conditions.solar_angle_location',
        description: 'Solar Angle Location',
        defaultMessage: 'Solar Angle Location',
    },
    temperature_model_parameters: {
        id: 'conditions.temperature_model_parameters',
        description: 'Temperature Model Parameters',
        defaultMessage: 'Temperature Model Parameters',
    },
    soiling: {
        id: 'conditions.soiling',
        description: 'Soiling',
        defaultMessage: 'Soiling',
    },
    irradiation_variance: {
        id: 'conditions.irradiation_variance',
        description: 'Irradiation Variance',
        defaultMessage: 'Irradiation Variance',
    },
    cell_temp_spread: {
        id: 'conditions.cell_temp_spread',
        description: 'Cell Temperature Spread',
        defaultMessage: 'Cell Temperature Spread',
    },
    module_binning_range: {
        id: 'conditions.module_binning_range',
        description: 'Module Binning Range',
        defaultMessage: 'Module Binning Range',
    },
    meteo_lat_lng: {
        id: 'conditions.meteo_lat_lng',
        description: 'Meteo Lat/Lng',
        defaultMessage: 'Meteo Lat/Lng',
    },
    horizon_profile: {
        id: 'conditions.horizon_profile',
        description: 'Horizon Profile',
        defaultMessage: 'Horizon Profile',
    },
    spectral_adjustment_model: {
        id: 'conditions.spectral_adjustment_model',
        description: 'Spectral Adjustment Model',
        defaultMessage: 'Spectral Adjustment Model (CdTe cells only)',
    },
    ac_derate: {
        id: 'conditions.ac_derate',
        description: 'AC System Derate',
        defaultMessage: 'AC System Derate',
    },
    tracker_parameters: {
        id: 'conditions.tracker_parameters',
        description: 'Tracker Parameters',
        defaultMessage: 'Tracker Parameters',
    },
    tracker_max_angle: {
        id: 'conditions.tracker_max_angle',
        description: 'Maximum Angle',
        defaultMessage: 'Maximum Angle',
    },
    tracker_backtrack: {
        id: 'conditions.tracker_backtrack',
        description: 'Backtracking',
        defaultMessage: 'Backtracking',
    },
});

export default CONDITIONS_STRINGS;
