/**
 * Predeclared messages file for react-intl
 *
 * The `defineMessages` call is so that all messages can be parsed out via AST for translation.
 */

import { defineMessages } from 'react-intl';

const PROJECT_STRINGS = defineMessages({
    address: {
        id: 'project.address',
        description: 'Address of solar project',
        defaultMessage: 'Address',
    },
    customer_name: {
        id: 'project.customer_name',
        description: 'Customer name for solar project',
        defaultMessage: 'Customer Name',
    },
    owner: {
        id: 'project.owner',
        description: 'Owner of solar project',
        defaultMessage: 'Owner',
    },
    last_modified: {
        id: 'project.last_modified',
        description: 'when the project was Last Modified',
        defaultMessage: 'Last Modified',
    },
    profile: {
        id: 'project.profile',
        description: 'Profile for solar project',
        defaultMessage: 'Profile',
    },
    design: {
        id: 'project.design',
        description: 'Design (i.e. solar design)',
        defaultMessage: 'Design',
    },
    project_lat_lng: {
        id: 'project_lat_lng',
        description: 'Project Lat/Lng',
        defaultMessage: 'Project Lat/Lng',
    },
});

export default PROJECT_STRINGS;
