/* tslint:disable:variable-name */
import moment from 'moment';
import * as stripe from '@stripe/stripe-js';

import { BaseClass } from 'reports/utils/api';
import { DeepPartial } from 'reports/utils/types';

/**
 * Based off the stripe Card object: https://stripe.com/docs/api/cards/object
 */
class Card extends BaseClass implements Partial<stripe.Card> {
    object: 'card';
    brand: string;
    last4: string;
    exp_month: number;
    exp_year: number;

    get expiration() {
        // Moment 0-indexes months. By leaving month as-is, we're effectively adding a month. That's correct since
        // the card expires on the first day of the following month.
        return moment({ year: this.exp_year, month: this.exp_month });
    }
}

/**
 * Based off the stripe PaymentMethod object: https://stripe.com/docs/api/payment_methods/object
 */
class PaymentMethod extends BaseClass implements DeepPartial<stripe.PaymentMethod> {
    object: 'payment_method';
    card?: Card;

    constructor(data) {
        super(PaymentMethod.deserializer(data));
    }

    static deserializer = BaseClass.getDeserializer({
        card: (x) => new Card(x),
    });
}

export { Card, PaymentMethod };
export default PaymentMethod;
